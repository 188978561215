import React from 'react';

import { I18n } from 'services';
import { ReactComponent as TrashIcon } from 'shared/images/trash.svg';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';

import { classificationsUnit } from '../../../units/inputState';
import './style.scss';

const b = block('classifications-section-item');

type Props = {
  data: M.ClassificationPaths;
};

function Item({ data }: Props) {
  const t = I18n.useGetMultilingProjectTranslation();

  const handleIconClick = () => {
    const { uuid } = data.path[data.path.length - 1];

    classificationsUnit.setState(prevState =>
      prevState.filter(x => x !== uuid),
    );
  };

  return (
    <li className={b()}>
      {data.path.map(path => t(path.caption)).join(' / ')}
      <TrashIcon className={b('icon')} onClick={handleIconClick} />
    </li>
  );
}

export const Component = React.memo(Item);
