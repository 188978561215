import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = M.EmulateParamsHolder & {
  map?: {
    answers?: string;
    approved?: string;
    cdate?: string;
    location?: string;
    project?: string;
    status?: string;
    user?: string;
    uuid?: string;
  };
  likes?: 1;
  resolveUser?: 1;
  resolveGroup?: 1;
  banned?: 1;
  filter:
    | (M.Filter & {
        project: M.UUID;
        batch?: M.UUID;
      })
    | null;
};

type APIOutputData = {
  data: M.QuestionnaireData[];
};

export const list = makeService<
  InputData,
  APIOutputData,
  M.QuestionnaireData[]
>(
  (data, convertToSearchParams) => {
    return `data/?${convertToSearchParams({
      ...data,
      filter: JSON.stringify(data.filter),
    })}`;
  },
  'api-v2-get',
  false,
  ({ data }) => data,
);
