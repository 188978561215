import * as R from 'ramda';
import React, { useCallback } from 'react';

import { ItemSelection } from 'components';
import {
  WidgetNavigationControls,
  WidgetDeleteControl,
} from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import * as widgetIcons from 'shared/images/widgetIcons';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../../../i18n.json';
import { WidgetKey } from '../../types';
import { instancesUnit, makeNewWidgetInstance } from '../../units';
import { constructorWidgets } from '../../widgets';
import { ConstructorWidgetContext } from '../Context';
import './style.scss';

const b = block('constructor-widget-for-question-selection');

type Props = {};

const Header = ItemSelection.Header.makeComponent({
  useTitle: () =>
    I18n.useText(i18nData).steps.findingsSettings.addWidgetSelect.header.title,
  useSubtitle: () =>
    I18n.useText(i18nData).steps.findingsSettings.addWidgetSelect.header
      .subtitle,
});

const ControlsBar = () => {
  const { onDelete, onMoveNext, onMovePrev } = useRequiredContext(
    ConstructorWidgetContext,
  );

  return (
    <>
      <WidgetNavigationControls.Component
        onMoveNext={onMoveNext}
        onMovePrev={onMovePrev}
      />
      <WidgetDeleteControl.Component onDelete={onDelete} />
    </>
  );
};

function ConstructorWidgetForWidgetSelection({}: Props) {
  const text = I18n.useText(i18nData).steps.findingsSettings;

  const { stateInstance } = useRequiredContext(ConstructorWidgetContext);

  const handleWidgetSelect = useCallback(
    (widgetKey: WidgetKey) => {
      instancesUnit.setState(prev => {
        const index = prev.findIndex(x => x === stateInstance);
        return R.update(
          index,
          makeNewWidgetInstance(widgetKey, prev[index].id),
          prev,
        );
      });
    },
    [stateInstance],
  );

  const Content = ItemSelection.Content.makeComponent({
    data: constructorWidgets.map(x => {
      const questionKey = x.key;

      return {
        title: text.widgets[x.key].name,
        itemKey: questionKey,
        iconSrc: widgetIcons[x.icon].larger,
      };
    }),
    onSelect: handleWidgetSelect,
  });

  return (
    <div className={b()}>
      <ItemSelection.Component
        Header={Header}
        Content={Content}
        ControlsBar={ControlsBar}
      />
    </div>
  );
}

export const Component = React.memo(ConstructorWidgetForWidgetSelection);
