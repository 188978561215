import debounce from 'debounce';
import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';

import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import {
  Filter,
  GalleryWidgetDatafulView,
} from 'features/project/Constructor/subfeatures';
import { serverProjectDataUnit } from 'features/project/Constructor/units';
import { API } from 'services';
import { makeMappingUnit } from 'utils/State';
import { QuestionnaireData } from 'utils/business';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { questionIDToQuestionInstanceUnit } from '../../../../InputDataForm/units/instances';
import * as modals from '../../../modals';
import { ViewProps } from '../../../types';
import * as DatalessWidgetLayout from '../../shared/DatalessWidgetLayout';
import { makeEmulateParams } from '../../shared/makeEmulateParams';
import { GalleryInstance } from '../types';

function View({
  instance,
  shouldEmulateDataUnit,
  useEmulationSeed,
}: ViewProps<GalleryInstance>) {
  const callStateUnit = API.services.data.list.useCallStateUnit();
  const callState = callStateUnit.useState();
  const call = API.services.data.list.useCall(callStateUnit);

  const emulationSeed = useEmulationSeed();

  const filterStateForRequestUnit = useMemo(
    () => Filter.makeFilterStateUnitForRequest(instance.filter),
    [instance.filter],
  );

  const settingsUnit = useMemo(() => {
    return makeMappingUnit({
      filter: filterStateForRequestUnit,
      shouldEmulate: shouldEmulateDataUnit,
    });
  }, [filterStateForRequestUnit, shouldEmulateDataUnit]);

  const { onQuestionnaireDelete, getProjectUUID } = useRequiredContext(
    ConstructorConfigContext,
  );

  const project = serverProjectDataUnit.useState();

  useEffect(() => {
    const requestData = (
      settings: ReturnType<(typeof settingsUnit)['getState']>,
      prevSettings?: ReturnType<(typeof settingsUnit)['getState']>,
    ) => {
      const projectUUID = getProjectUUID();

      if (
        projectUUID &&
        !R.equals(settings, prevSettings) &&
        instance.filter.validate()
      ) {
        call({
          resolveUser: 1,
          filter: {
            project: projectUUID,
            ...Filter.makeServerFilter(settings.filter),
          },
          emulate: makeEmulateParams(settings.shouldEmulate, emulationSeed),
        });
      }
    };

    requestData(settingsUnit.getState());

    return settingsUnit.subscribe({
      name: 'data-requester',
      callback: debounce(requestData, 500),
    });
  }, [call, emulationSeed, getProjectUUID, instance.filter, settingsUnit]);

  const questionID = instance.questionID.units.value.useState();

  useEffect(() => {
    return callStateUnit.subscribe({
      name: 'error-modal-opener',
      callback: callState => {
        switch (callState.kind) {
          case 'successful': {
            const questionID = instance.questionID.units.value.getState();
            const isError =
              shouldEmulateDataUnit.getState() &&
              questionID !== null &&
              callState.data.filter(
                x =>
                  QuestionnaireData.answerHasImages(x.answers[questionID]) ||
                  QuestionnaireData.answerHasVideo(x.answers?.[questionID]),
              ).length === 0 &&
              Object.values(filterStateForRequestUnit.getState()).every(
                x => x === null,
              );
            if (isError) {
              modals.QuestionsNotSavedOrOtherError.open();
            }
            break;
          }
        }
      },
    });
  }, [
    callStateUnit,
    filterStateForRequestUnit,
    instance.questionID.units.value,
    shouldEmulateDataUnit,
  ]);

  const DatalessView = DatalessWidgetLayout.useViewWithLayout(
    'gallery',
    shouldEmulateDataUnit,
  );

  return API.renderCallState(callState, {
    successful: ({ data }) => {
      if (questionID === null || project === null) return <DatalessView />;

      const questionnaireDataForWidget = data.filter(
        x =>
          QuestionnaireData.answerHasImages(x.answers?.[questionID]) ||
          QuestionnaireData.answerHasVideo(x.answers?.[questionID]),
      );

      const questionIDToQuestionInstance =
        questionIDToQuestionInstanceUnit.getState();

      const questionInstance = questionIDToQuestionInstance[questionID];

      if (questionnaireDataForWidget.length === 0 || !questionInstance) {
        return <DatalessView />;
      }

      return (
        <GalleryWidgetDatafulView.Component
          data={R.reverse(questionnaireDataForWidget)}
          questionID={questionID}
          questionType={questionInstance.kind === 'image' ? 'image' : 'video'}
          project={project}
          onQuestionnaireDelete={onQuestionnaireDelete}
        />
      );
    },
    error: () => <DatalessView />,
    initial: () => <DatalessView />,
  });
}

export const Component = React.memo(View);
