import React, { useEffect, useMemo, useRef } from 'react';

import { ErrorMessage } from 'components';
import { I18n } from 'services';
import { QUESTION_VISIBILITY } from 'shared/constants';
import { projectLangsUnit } from 'shared/stateUnits';
import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonNull } from 'utils/validators';

import { questionIsRequired } from '../../i18nSharedReferences';
import { VariantSelectionQuestion } from '../../subfeatures';
import { Kind, Type } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';
import { makeElementModels } from './makeElementModels';
import { useModel } from './useModel';

type Props = {
  data: M.SingleChoiceQuestion | M.SingleChoiceQuizQuestion;
  num: number;
  kind: Kind;
  type: Type;
  initialValue?: string[];
  initialText?: Record<string, string>;
  result?: M.QuizAnswerResult;
  onChange?(): void;
};

export const stateUnit = makePrimaryUnit<
  Record<
    string,
    FormElementState<VariantSelectionQuestion.SingleChoiceElementModel | null>
  >
>({});

function SingleChoiceQuestion({
  data,
  num,
  initialValue,
  initialText,
  kind,
  type,
  result,
  onChange,
}: Props) {
  const variant = useMemo(
    () => data.variants.find(x => x.uuid === initialValue?.[0]),
    [data.variants, initialValue],
  );

  const lang = I18n.activeLangStateUnit.useState();
  const projectLangs = projectLangsUnit.useState();

  const isOptional = 'optional' in data && data.optional;
  const visibility = 'visibility' in data && data.visibility;

  const initValue = useRef(
    variant
      ? makeElementModels(
          variant,
          lang,
          projectLangs,
          initialText?.[variant.uuid],
        )
      : null,
  );

  const validators = useMemo(
    () => (!isOptional ? [nonNull(questionIsRequired)] : []),
    [isOptional],
  );

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    defaultValue: null,
    initialValue: initValue.current,
    validators,
  });

  const value = formElementState.units.value.useState();
  const error = formElementState.units.error.useState();

  const t = I18n.useGetMultilingProjectTranslation();

  const title = t(data.title);
  const description = t(data.description);
  const answer = t(data.answer);

  const model = useModel(data, formElementState, initialText);

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  useEffect(() => {
    return formElementState.units.value.subscribe({
      name: 'change-value',
      callback: (nextState, prevState) => {
        setTimeout(() => {
          const visited = formElementState.units.visited.getState();

          if (nextState !== prevState && visited) {
            onChange?.();
          }
        });
      },
    });
  }, [formElementState, onChange]);

  return (
    <QuestionLayout.Component
      title={title}
      description={description}
      image={data.image?.large}
      type={type}
      num={num}
      variant={
        (type === 'quiz' && kind === 'form') || value === null
          ? 'outlined'
          : 'contained'
      }
      isOptional={isOptional}
      isEmpty={kind === 'view' && value === null}
      isAutocomplete={visibility === QUESTION_VISIBILITY.disabled}
      result={result}
      answer={kind === 'view' ? answer : undefined}
    >
      <VariantSelectionQuestion.Component
        model={model}
        kind={kind}
        onInputBlur={onChange}
      />
      {kind === 'form' && (
        <ErrorMessage.Component rows={1} messageReference={error} />
      )}
    </QuestionLayout.Component>
  );
}
export const Component = React.memo(SingleChoiceQuestion);
