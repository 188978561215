import debounce from 'debounce';
import React, { useEffect, useMemo, useRef } from 'react';

import { ErrorMessage } from 'components';
import { I18n } from 'services';
import { QUESTION_VISIBILITY } from 'shared/constants';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import {
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
} from 'utils/State';
import { nonEmptyString, nonFalse } from 'utils/validators';

import { isRequired, questionIsRequired } from '../../i18nSharedReferences';
import { VariantSelectionQuestion } from '../../subfeatures';
import { Kind, Type } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';
import { State, StateItem } from './types';
import { useModel } from './useModel';

type Props = {
  data: M.MultiChoiceQuestion | M.MultiChoiceQuizQuestion;
  num: number;
  kind: Kind;
  type: Type;
  initialValue?: string[];
  initialText?: Record<string, string>;
  result?: M.QuizAnswerResult;
  onChange?(): void;
};

export const stateUnit = makePrimaryUnit<State>({});

function MultipleChoiceQuestion({
  data,
  num,
  initialValue,
  initialText,
  kind,
  type,
  result,
  onChange,
}: Props) {
  const isOptional = 'optional' in data && data.optional;
  const visibility = 'visibility' in data && data.visibility;

  const defaultValue = useRef(
    (data.variants as M.Variant[]).reduce(
      (acc, x) => ({
        ...acc,
        [x.uuid]: {
          valueState: makeFormElementState(
            false,
            !isOptional ? [nonFalse(questionIsRequired)] : [],
          ),
          textState: makeFormElementState(
            '',
            !isOptional && x.custom ? [nonEmptyString(isRequired)] : [],
          ),
        },
      }),
      {},
    ),
  );

  const initValue = useMemo(
    (): StateItem =>
      (data.variants as M.Variant[]).reduce(
        (acc, x) => ({
          ...acc,
          [x.uuid]: {
            valueState: makeFormElementState(
              !!initialValue?.includes(x.uuid),
              !isOptional ? [nonFalse(questionIsRequired)] : [],
            ),
            textState: makeFormElementState(
              initialText?.[x.uuid] || '',
              !isOptional && x.custom ? [nonEmptyString(isRequired)] : [],
            ),
          },
        }),
        {},
      ),
    [data.variants, initialText, initialValue, isOptional],
  );

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    defaultValue: defaultValue.current,
    initialValue: initValue,
  });

  const state = formElementState.units.value.useState();
  const valuesUnit = useMemo(
    () =>
      makeMappingUnitFromUnit(
        makeDerivedUnit(formElementState.units.value).getUnit(x =>
          Object.values(x).map(x => x.valueState.units.value),
        ),
      ),
    [formElementState.units.value],
  );
  const errorsUnit = useMemo(
    () =>
      makeMappingUnitFromUnit(
        makeDerivedUnit(formElementState.units.value).getUnit(x =>
          Object.values(x).map(x => x.valueState.units.error),
        ),
      ),
    [formElementState.units.value],
  );
  const values = valuesUnit.useState();
  const errors = errorsUnit.useState();

  const isSelected = values.some(x => x);

  const error = useMemo(() => {
    const isErrors = errors.every(x => x);
    if (isErrors) {
      return errors.find(x => x !== null) || null;
    }
    return null;
  }, [errors]);

  const t = I18n.useGetMultilingProjectTranslation();

  const title = t(data.title);
  const description = t(data.description);
  const answer = t(data.answer);

  const model = useModel(data, state);

  const derivedUnit = useMemo(() => {
    return makeDerivedUnit(
      makeMappingUnitFromUnit(
        makeDerivedUnit(formElementState.units.value).getUnit(item =>
          Object.values(item).map(x => x.valueState.units.value),
        ),
      ),
    ).getUnit(x => x);
  }, [formElementState.units.value]);

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  useEffect(() => {
    return derivedUnit.subscribe({
      name: 'change-value',
      callback: debounce(() => onChange?.(), 500),
    });
  }, [derivedUnit, onChange]);

  return (
    <QuestionLayout.Component
      title={title}
      description={description}
      image={data.image?.large}
      type={type}
      num={num}
      variant={
        (type === 'quiz' && kind === 'form') || !isSelected
          ? 'outlined'
          : 'contained'
      }
      isOptional={isOptional}
      isEmpty={kind === 'view' && !isSelected}
      isAutocomplete={visibility === QUESTION_VISIBILITY.disabled}
      result={result}
      answer={kind === 'view' ? answer : undefined}
    >
      <VariantSelectionQuestion.Component
        model={model}
        kind={kind}
        onInputBlur={onChange}
      />
      {kind === 'form' && (
        <ErrorMessage.Component rows={1} messageReference={error} />
      )}
    </QuestionLayout.Component>
  );
}

export const Component = React.memo(MultipleChoiceQuestion);
