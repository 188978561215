import * as R from 'ramda';
import React, { useCallback, useEffect, useLayoutEffect } from 'react';

import { Button, VerticallyMovableElement } from 'components';
import { ConstructorFormSection } from 'features/project/Constructor/subfeatures';
import { serverProjectDataUnit } from 'features/project/Constructor/units';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { scrollToElement } from 'utils/DOM';
import { makeDerivedUnit, makeMappingUnitFromUnit } from 'utils/State';
import { block } from 'utils/classname';
import { deepOmit } from 'utils/object';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../i18n.json';
import { ProjectWriteContext } from '../../ProjectWriteContext';
import { Step } from '../../types';
import { makeStepVisitedUnit } from '../../utils';
import * as ConstructorWidget from './ConstructorWidget';
import * as OrderingModeButton from './OrderingModeButton';
import * as QuestionsBlocks from './QuestionsBlocks';
import { makeWidgetInstanceFromServerWidget } from './makeWidgetInstanceFromServerWidget';
import * as modals from './modals';
import './style.scss';
import { ConstructorWidgetInstance } from './types';
import {
  instancesUnit,
  makeInitialInstances,
  makeSelectionInstance,
  selectedWidgetInstances,
} from './units';
import { errorsUnit } from './units/errors';
import { hasSomeInputUnit } from './units/hasSomeInput';
import { progressInvariantUnitsUnit } from './units/stepInvariants';
import { widgetIDToScrollToUnit } from './widgetIDToScrollToUnit';
import { FindingsWidgetInstance } from './widgets';

export type { FindingsWidgetInstance, chartWidgets } from './widgets';
export type { WidgetKey } from './types';

const b = block('findings-settings');

type Props = {};

const useVerticallyMovableELements =
  VerticallyMovableElement.makeUseVerticallyMovableELements<ConstructorWidgetInstance>(
    x => x.id,
  );

const instancesDerivedUnit = makeDerivedUnit(
  makeMappingUnitFromUnit(
    makeDerivedUnit(
      makeMappingUnitFromUnit(
        makeDerivedUnit(instancesUnit).getUnit(instances =>
          instances.filter(
            (x): x is FindingsWidgetInstance =>
              x.kind !== 'select-instance-kind',
          ),
        ),
      ),
    ).getUnit(instances => instances.filter(x => x.mode === 'preview')),
    {
      deep: true,
    },
  ),
).getUnit(instances =>
  instances.map(instance =>
    deepOmit(
      ['visited', 'isValid', 'validationIsPending', 'error', 'disabled'],
      instance,
    ),
  ),
);

const isAddWidgetButtonDisabledUnit = makeDerivedUnit(
  makeMappingUnitFromUnit(
    makeDerivedUnit(instancesUnit).getUnit(questionInstances =>
      questionInstances.map(
        instance =>
          instance.kind === 'select-instance-kind' ||
          makeDerivedUnit(instance.mode).getUnit(x => x === 'edit'),
      ),
    ),
  ),
).getUnit(x => x.some(x => x));

function FindingsSettings({}: Props) {
  const { saveProject } = useRequiredContext(ProjectWriteContext);
  const text = I18n.useText(i18nData).steps.findingsSettings;

  const handleAddWidgetButtonClick = useCallback(() => {
    instancesUnit.setState(prev => [...prev, makeSelectionInstance()]);
  }, []);

  const instances = instancesUnit.useState();
  const movableInstances = useVerticallyMovableELements(instances);

  const isAddWidgetButtonDisabled = isAddWidgetButtonDisabledUnit.useState();

  const widgetIDToScroll = widgetIDToScrollToUnit.useState();

  useEffect(() => {
    return instancesDerivedUnit.subscribe({
      name: 'saving-the-project',
      callback: () => {
        saveProject();
      },
    });
  }, [saveProject]);

  useLayoutEffect(() => {
    // NOTE React bug
    // first layout effect is not being run after dom change (as stated by doc) after the first widget addition by QuestionsBlocks
    // so we can't find widget with given ID for the first try
    if (widgetIDToScroll !== null) {
      const element = document.getElementById(widgetIDToScroll);
      if (element === null) {
        setTimeout(() => {
          const secondTryElement = document.getElementById(widgetIDToScroll);
          if (secondTryElement !== null) {
            scrollToElement(secondTryElement);
          }
        }, 100);
      } else {
        scrollToElement(element);
      }
      widgetIDToScrollToUnit.setState(null);
    }
  }, [widgetIDToScroll]);

  return (
    <div className={b()}>
      <modals.QuestionsNotSavedOrOtherError.Component />
      <modals.IncompleteSettings.Component />
      <ConstructorFormSection.Component hasAccent>
        <h2 className={b('questions-section-title')}>
          {text.questionsSectionTitle}
        </h2>
        <p className={b('questions-section-intro')}>
          {text.questionsSectionIntro}
        </p>
        <QuestionsBlocks.Component />
        <div className={b('ordering-mode-button-container')}>
          <OrderingModeButton.Component />
        </div>
      </ConstructorFormSection.Component>
      {movableInstances.map((instance, index) => {
        const prevInstance =
          index > 0 ? movableInstances[index - 1].value : null;
        return (
          <ConstructorWidget.Component
            key={instance.value.id}
            constructorWidgetInstance={instance}
            prevWidgetHasErrorUnit={
              prevInstance !== null &&
              prevInstance.kind !== 'select-instance-kind'
                ? prevInstance.hasErrorUnit
                : undefined
            }
          />
        );
      })}
      <ConstructorFormSection.Component>
        <Button.Component
          type="button"
          disabled={isAddWidgetButtonDisabled}
          onClick={handleAddWidgetButtonClick}
        >
          {text.addWidgetButtonLabel}
        </Button.Component>
      </ConstructorFormSection.Component>
    </div>
  );
}

export const Component = React.memo(FindingsSettings);

const visitedUnit = makeStepVisitedUnit();

export const step: Step = {
  key: 'findingsSettings',
  Form: Component,
  errorsUnit,
  visitedUnit,
  progressInvariantUnitsUnit,
  hasSomeInputUnit,
  getProjectData: () => ({
    resultWidgets: selectedWidgetInstances
      .getState()
      .map((x): M.Widget | null => {
        const serverProject = serverProjectDataUnit.getState();
        const mode = x.mode.getState();

        if (mode === 'edit') {
          const serverWidget = serverProject?.resultWidgets.find(
            widget => widget.uuid === x.id,
          );

          return serverWidget || null;
        }
        return R.mergeDeepRight(
          x.makeSharedServerWidget(),
          x.makeServerWidget(),
        ) as M.Widget;
      })
      .filter((x): x is M.Widget => x !== null),
  }),
  fillFromExistingProject: ({ project, language }) => {
    instancesUnit.setState(
      project.resultWidgets.map(widget =>
        makeWidgetInstanceFromServerWidget(widget, project, language),
      ),
    );
  },
  resetState: () => {
    instancesUnit.setState(makeInitialInstances());
    visitedUnit.resetState();
  },
};

export { selectedWidgetInstances };
