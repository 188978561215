import React, { useMemo, useCallback, useEffect } from 'react';

import { ErrorMessage } from 'components';
import { I18n } from 'services';
import { QUESTION_VISIBILITY } from 'shared/constants';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit, makeMappingUnitFromUnit } from 'utils/State';
import { nonEmptyArray } from 'utils/validators';

import { questionIsRequired, isRequired } from '../../i18nSharedReferences';
import { MatchQuestion } from '../../subfeatures';
import { Kind, Type } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';
import { Data, State, StateItem } from './types';

type Props = {
  data: Data;
  type: Type;
  kind: Kind;
  num: number;
  initialValue?: Data['matchings'];
  result?: M.QuizAnswerResult;
};

export const stateUnit = makePrimaryUnit<State>({});

const defaultValue = {};

function Question({
  data,
  type,
  kind,
  num,
  initialValue = defaultValue,
  result,
}: Props) {
  // TODO: if M.Question is available
  // const isOptional = 'optional' in data && data.optional;
  // const visibility = 'visibility' in data && data.visibility;
  const isOptional = false;
  const visibility = false;

  const matchValue = useMemo(
    () =>
      data.columns.left.elements.reduce<StateItem>(
        (acc, x) => ({
          ...acc,
          [x.uuid]: makeFormElementState(
            initialValue[x.uuid] || [],
            !isOptional ? [nonEmptyArray(isRequired)] : [],
          ),
        }),
        {},
      ),
    [data.columns.left.elements, initialValue, isOptional],
  );

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    defaultValue: defaultValue,
    initialValue: matchValue,
  });

  const mappedValue = useMemo(
    () => makeMappingUnitFromUnit(formElementState.units.value),
    [formElementState.units.value],
  ).useState();

  const hasSomeValue = useMemo(
    () => Object.values(mappedValue).some(x => x.units.value.length > 0),
    [mappedValue],
  );

  const error = useMemo(() => {
    const errors = Object.values(mappedValue).map(x => x.units.error);

    return errors.some(x => !!x) ? questionIsRequired : null;
  }, [mappedValue]);

  const useTranslation = useCallback(function useTranslation() {
    return I18n.useGetMultilingProjectTranslation();
  }, []);
  const t = useTranslation();

  const title = t(data.title);
  const description = t(data.description);
  const answer = t(data.answer);

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  return (
    <QuestionLayout.Component
      type={type}
      title={title}
      description={description}
      image={data.image?.large}
      num={num}
      variant={
        (type === 'quiz' && kind === 'form') || !hasSomeValue
          ? 'outlined'
          : 'contained'
      }
      isOptional={isOptional}
      isEmpty={kind === 'view' && !hasSomeValue}
      isAutocomplete={visibility && visibility === QUESTION_VISIBILITY.disabled}
      result={result}
      answer={kind === 'view' ? answer : undefined}
    >
      <MatchQuestion.Component
        formElementState={formElementState}
        model={data}
        kind={kind}
        useTranslation={useTranslation}
      />
      {kind === 'form' && (
        <ErrorMessage.Component rows={1} messageReference={error} />
      )}
    </QuestionLayout.Component>
  );
}
export const Component = React.memo(Question);
