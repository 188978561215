import * as R from 'ramda';

import * as M from 'types/serverModels';

const fields = [
  'about',
  'allow_anonymous_data',
  'classifications',
  'conclusions',
  'crowdsourcing_justification',
  'description',
  'equipment',
  'group_access',
  'hypothesis',
  'is_pb_project',
  'keywords',
  'langs',
  'private',
  'problem',
  'protocol',
  'questions',
  'research_aim',
  'resultWidgets',
  'safety',
  'stages',
  'subjects',
  'targetAges',
  'thumb',
  'title',
  'units',
  'weight',
] as const;

export function convertToProjectWriteData(
  project: Partial<M.Project>,
): Partial<M.ProjectWriteData> {
  return R.pick(fields, project);
}

export function widgetDescriptorEquals(
  serverDescriptor: M.Widget['descriptor'],
  descriptor: M.Widget['descriptor'],
) {
  return Object.entries(descriptor).every(([key, x]) => {
    const property = key as keyof M.Widget['descriptor'];

    return R.equals(x, serverDescriptor[property]);
  });
}

export function widgetsEquals(serverWidgets: M.Widget[], widgets: M.Widget[]) {
  if (serverWidgets.length !== widgets.length) {
    return false;
  }

  return widgets.every((widget, index) => {
    const serverWidget = serverWidgets[index];

    if (!serverWidget) return false;

    const isBasicDataEquals = Object.entries(
      R.omit(['descriptor'], widget),
    ).every(([key, x]) => R.equals(x, serverWidget[key as keyof M.Widget]));

    const isDescriptorEquals = widgetDescriptorEquals(
      serverWidget.descriptor,
      widget.descriptor,
    );

    return isBasicDataEquals && isDescriptorEquals;
  });
}

export function questionsEquals(
  serverQuestions: M.Question[],
  questions: M.Question[],
) {
  if (serverQuestions.length !== questions.length) {
    return false;
  }
  return questions.every((question, index) => {
    const serverQuestion = serverQuestions[index];

    if (!serverQuestion) return false;

    switch (serverQuestion.type) {
      case 'probe':
        return R.equals(R.omit(['_sensors'], serverQuestion), question);
      default:
        return R.equals(serverQuestion, question);
    }
  });
}

export function equals(
  serverProject: M.Project | null,
  projectWriteData: M.ProjectWriteData,
) {
  if (!serverProject) {
    return false;
  }

  const isBasicDataEquals = Object.entries(
    R.omit(['questions', 'resultWidgets'], projectWriteData),
  ).every(([key, x]) => {
    const property = key as keyof M.ProjectWriteData;
    const isEmpty = !x && !serverProject[property];

    return isEmpty || R.equals(x, serverProject[property]);
  });

  const isWidgetsEquals = widgetsEquals(
    serverProject.resultWidgets,
    projectWriteData.resultWidgets,
  );

  const isQuestionsEquals = questionsEquals(
    serverProject.questions,
    projectWriteData.questions,
  );

  return isBasicDataEquals && isWidgetsEquals && isQuestionsEquals;
}

export function difference(
  serverProject: M.Project,
  projectWriteData: M.ProjectWriteData,
): Partial<M.ProjectWriteData> {
  return Object.entries(projectWriteData).reduce((acc, [key, x]) => {
    const property = key as keyof M.ProjectWriteData;
    const isEmpty = !x && !serverProject[property];

    switch (property) {
      case 'questions': {
        if (
          !questionsEquals(serverProject.questions, projectWriteData.questions)
        ) {
          return { ...acc, [property]: x };
        }
        return acc;
      }
      case 'resultWidgets': {
        if (
          !widgetsEquals(
            serverProject.resultWidgets,
            projectWriteData.resultWidgets,
          )
        ) {
          return { ...acc, [property]: x };
        }
        return acc;
      }
      default: {
        if (!isEmpty && !R.equals(x, serverProject[property])) {
          return { ...acc, [property]: x };
        }
        return acc;
      }
    }
  }, {});
}
