import * as R from 'ramda';
import React, { useCallback, useMemo } from 'react';

import { Button, VerticallyMovableElement } from 'components';
import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import { ConstructorFormSection } from 'features/project/Constructor/subfeatures';
import { I18n, IDProvider } from 'services';
import { QuestionKind } from 'types';
import * as M from 'types/serverModels';
import { makeDerivedUnit, makeMappingUnitFromUnit } from 'utils/State';
import { makeUUID } from 'utils/UUID';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../i18n.json';
import { ProjectWriteContext } from '../../ProjectWriteContext';
import { EditError } from '../../modals';
import { Step } from '../../types';
import { makeStepVisitedUnit } from '../../utils';
import { anonymousQuestionnairesSwitchState } from '../Access/units';
import { Question, useIsEditAccess } from '../shared';
import { PermissionContext } from '../shared/PermissionContext';
import { QuestionsConfigContext } from '../shared/Question';
import * as Preview from './Preview';
import * as WidgetGroupHeader from './WidgetGroupHeader';
import { config } from './config';
import * as errorModals from './errorModals';
import { makeServerQuestion } from './makeServerQuestion';
import { makeServerStages } from './makeServerStages';
import './style.scss';
import { errorsUnit } from './units/errors';
import { hasSomeInputUnit } from './units/hasSomeInput';
import {
  cachedStoreInstancesUnit,
  flatCachedInstancesUnit,
  makeInitialQuestionInstances,
  questionInstancesUnit,
} from './units/instances';
import { progressInvariantUnitsUnit } from './units/stepInvariants';

const b = block('input-data-form');

type Props = {};

const useVerticallyMovableELements =
  VerticallyMovableElement.makeUseVerticallyMovableELements<Question.InstanceView>(
    x => (x.kind === 'group-header' ? x.id : x.instance.id),
  );

const isAddQuestionButtonDisabledUnit = makeDerivedUnit(
  makeMappingUnitFromUnit(
    makeDerivedUnit(flatCachedInstancesUnit).getUnit(questionInstances =>
      questionInstances.map(
        instance =>
          instance.kind === 'select-instance-kind' ||
          makeDerivedUnit(instance.mode).getUnit(x => x === 'edit'),
      ),
    ),
  ),
).getUnit(x => x.some(x => x));

function InputDataForm({}: Props) {
  const text = I18n.useText(i18nData);
  const instances = cachedStoreInstancesUnit.useState();

  const { mode } = useRequiredContext(ConstructorConfigContext);
  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const { isEditAccess, errorCode } = useIsEditAccess();

  const isAddQuestionButtonDisabled =
    isAddQuestionButtonDisabledUnit.useState();

  const getGroupID = IDProvider.useGetID('group');

  const instanceViews = useMemo(
    () => Question.getInstanceViews(instances),
    [instances],
  );

  const handleAddQuestionButtonClick = useCallback(() => {
    if (!isEditAccess) {
      EditError.errorCodeUnit.setState(errorCode);
      return;
    }
    cachedStoreInstancesUnit.setState(prev => {
      if (prev.kind === 'ungrouped') {
        return {
          ...prev,
          instances: [...prev.instances, Question.makeSelectionInstance()],
        };
      }

      const lastGroupInstancesLens = R.lensPath<
        Question.GroupedStoreCachedInstances,
        'groups',
        number,
        'instances'
      >(['groups', prev.groups.length - 1, 'instances']);

      return R.over(
        lastGroupInstancesLens,
        instances => [...instances, Question.makeSelectionInstance()],
        prev,
      );
    });
  }, [errorCode, isEditAccess]);

  let groupCounter = 0;

  const movableInstanceViews = useVerticallyMovableELements(instanceViews);

  const handleAddStageButtonClick = useCallback(() => {
    if (!isEditAccess) {
      EditError.errorCodeUnit.setState(errorCode);
      return;
    }
    if (anonymousQuestionnairesSwitchState.getValue()) {
      EditError.errorCodeUnit.setState('stageCannotBeAdded');
      return;
    }
    cachedStoreInstancesUnit.setState(prev => {
      if (prev.kind === 'ungrouped') {
        return {
          kind: 'grouped',
          groups: [
            {
              name: '',
              id: getGroupID(),
              instances: prev.instances,
            },
            {
              name: '',
              id: getGroupID(),
              instances: [],
            },
          ],
        };
      }

      return {
        ...prev,
        groups: [
          ...prev.groups,
          {
            name: '',
            id: getGroupID(),
            instances: [],
          },
        ],
      };
    });
    saveProject();
  }, [errorCode, getGroupID, isEditAccess, saveProject]);

  const handleStageDelete = useCallback(
    (id: string) => {
      if (!isEditAccess) {
        EditError.errorCodeUnit.setState(errorCode);
        return;
      }
      const instances = cachedStoreInstancesUnit.getState();
      if (instances.kind === 'grouped') {
        const groupIndex = instances.groups.findIndex(x => x.id === id);

        if (groupIndex === 0 && instances.groups.length > 1) {
          errorModals.EmptyGroups.isOpenUnit.setState(true);
          return;
        }
      }

      cachedStoreInstancesUnit.setState(prev => {
        if (prev.kind === 'grouped') {
          if (prev.groups.length === 2) {
            return {
              kind: 'ungrouped',
              instances: prev.groups[0].instances,
            };
          }

          const groupIndex = prev.groups.findIndex(x => x.id === id);
          if (groupIndex === -1) {
            return prev;
          }

          const prevGroupInstancesLens = R.lensPath<
            Question.GroupedStoreCachedInstances,
            'groups',
            number,
            'instances'
          >(['groups', groupIndex - 1, 'instances']);
          const addInstancesToPrevGroup = R.over(
            prevGroupInstancesLens,
            instances => [...instances, ...prev.groups[groupIndex].instances],
          );

          const deleteGroup = (
            instances: Question.GroupedStoreCachedInstances,
          ): Question.GroupedStoreCachedInstances => ({
            ...instances,
            groups: instances.groups.filter(x => x.id !== id),
          });

          return [addInstancesToPrevGroup, deleteGroup].reduce(
            (acc, f) => f(acc),
            prev,
          );
        }

        return prev;
      });
      saveProject();
    },
    [errorCode, isEditAccess, saveProject],
  );

  const { getQuestionDeletePermission, getQuestionTypeChangePermission } =
    useRequiredContext(PermissionContext);

  const isDeletionPermitted = useMemo(() => {
    return (questionID: string) => {
      if (!isEditAccess) {
        EditError.errorCodeUnit.setState(errorCode);
        return false;
      }
      const deletePermission = getQuestionDeletePermission(questionID);

      if (deletePermission.kind === 'denied') {
        errorModals.UnpermittedManipulationWithWidgetData.openModal({
          associatedWidgets: deletePermission.associatedWidgets,
          manipulationKind: 'question-delete',
        });

        return false;
      }

      return true;
    };
  }, [errorCode, getQuestionDeletePermission, isEditAccess]);

  const handleQuestionTypeChange = useCallback(
    (
      cachedInstance: Question.CachedQuestionInstance,
      questionKey: Question.QuestionKey,
      setQuestionType: (questionKey: Question.QuestionKey) => void,
    ) => {
      if (!isEditAccess) {
        EditError.errorCodeUnit.setState(errorCode);
        setQuestionType(cachedInstance.activeQuestionKeyUnit.getState());
        return;
      }
      const permission = getQuestionTypeChangePermission(
        cachedInstance.id,
        Question.getQuestionKindsSet(questionKey),
      );

      if (permission.kind === 'denied') {
        errorModals.UnpermittedManipulationWithWidgetData.openModal({
          associatedWidgets: permission.associatedWidgets,
          manipulationKind: 'question-type-change',
        });

        setQuestionType(cachedInstance.activeQuestionKeyUnit.getState());
      } else {
        Question.setInstanceQuestionKind(
          cachedInstance,
          permission.questionKind,
        );
      }
    },
    [errorCode, getQuestionTypeChangePermission, isEditAccess],
  );

  const getQuestionTypeChangeIsPermitted = useMemo(() => {
    return (instanceID: string, questionKind: QuestionKind) => {
      if (!isEditAccess) {
        EditError.errorCodeUnit.setState(errorCode);
        return false;
      }
      const permission = getQuestionTypeChangePermission(instanceID, [
        questionKind,
      ]);

      if (permission.kind === 'denied') {
        errorModals.UnpermittedManipulationWithWidgetData.openModal({
          associatedWidgets: permission.associatedWidgets,
          manipulationKind: 'question-type-change',
        });

        return false;
      }

      return true;
    };
  }, [errorCode, getQuestionTypeChangePermission, isEditAccess]);

  return (
    <div className={b()}>
      <errorModals.EmptyGroups.Component />
      <errorModals.UnpermittedManipulationWithWidgetData.Component />
      <Question.QuestionsConfigContext.Provider {...config}>
        <Question.QuestionTypeChangePermissionContext.Provider
          value={{ getQuestionTypeChangeIsPermitted }}
        >
          {movableInstanceViews.map((movableInstanceView, index) => {
            if (movableInstanceView.value.kind === 'widget') {
              const prevInstance =
                index > 0 ? movableInstanceViews[index - 1].value : null;
              return (
                <Question.ConstructorWidget.Component
                  key={movableInstanceView.value.instance.id}
                  isDeletionPermitted={isDeletionPermitted}
                  onQuestionTypeChange={handleQuestionTypeChange}
                  cachedStoreInstancesUnit={cachedStoreInstancesUnit}
                  widgetInstanceView={
                    movableInstanceView as VerticallyMovableElement.Element<Question.WidgetInstanceView>
                  }
                  prevInstance={
                    prevInstance?.kind === 'widget'
                      ? prevInstance.instance
                      : undefined
                  }
                />
              );
            }
            groupCounter += 1;
            return (
              <WidgetGroupHeader.Component
                key={movableInstanceView.value.id}
                groupHeaderView={
                  movableInstanceView as VerticallyMovableElement.Element<Question.GroupHeaderView>
                }
                groupCounter={groupCounter}
                onDelete={handleStageDelete}
              />
            );
          })}
        </Question.QuestionTypeChangePermissionContext.Provider>
      </Question.QuestionsConfigContext.Provider>
      <ConstructorFormSection.Component>
        <div className={b('add-question-and-stage-buttons')}>
          <Button.Component
            type="button"
            disabled={isAddQuestionButtonDisabled}
            className={b('add-question-button')}
            onClick={handleAddQuestionButtonClick}
          >
            {text.steps.shared.questions.addQuestionButtonLabel}
          </Button.Component>
          {mode !== 'compact' && (
            <Button.Component
              type="button"
              className={b('add-stage-button')}
              variant="outlined"
              color="accent-2"
              onClick={handleAddStageButtonClick}
            >
              {text.steps.inputDataForm.addStageButtonLabel}
            </Button.Component>
          )}
        </div>
      </ConstructorFormSection.Component>
    </div>
  );
}

const Component = React.memo(InputDataForm);

const visitedUnit = makeStepVisitedUnit();

export const step: Step = {
  key: 'inputDataForm',
  Form: Component,
  Preview: () => (
    <QuestionsConfigContext.Provider {...config}>
      <Preview.Component />
    </QuestionsConfigContext.Provider>
  ),
  errorsUnit,
  visitedUnit,
  progressInvariantUnitsUnit,
  hasSomeInputUnit,
  getProjectData: () => ({
    questions: questionInstancesUnit
      .getState()
      .map(makeServerQuestion)
      .filter((x): x is M.Question => x !== null),
    stages: makeServerStages(cachedStoreInstancesUnit.getState()),
  }),
  fillFromExistingProject: ({ project, language, mode }) => {
    const t = I18n.makeGetMultilingTranslation(language);

    if (project.stages && project.stages.length > 0 && mode === 'full') {
      cachedStoreInstancesUnit.setState({
        kind: 'grouped',
        groups: project.stages.map(
          ({ start, stop, title }): Question.InstanceGroup => ({
            id: makeUUID(),
            instances: project.questions.slice(start, stop + 1).map(x => {
              const instance = Question.makeQuestionInstanceFromServerQuestion(
                x,
                language,
              );

              return Question.makeCachedInstanceFromQuestionInstance(
                instance,
                instance.isValid() ? 'preview' : 'edit',
                config.questionKeyToConstructorWidget,
              );
            }),
            name: t(title),
          }),
        ),
      });
    } else {
      cachedStoreInstancesUnit.setState({
        kind: 'ungrouped',
        instances: project.questions.map(x => {
          const instance = Question.makeQuestionInstanceFromServerQuestion(
            x,
            language,
          );
          return Question.makeCachedInstanceFromQuestionInstance(
            instance,
            instance.isValid() ? 'preview' : 'edit',
            config.questionKeyToConstructorWidget,
          );
        }),
      });
    }
  },
  resetState: () => {
    cachedStoreInstancesUnit.setState({
      kind: 'ungrouped',
      instances: makeInitialQuestionInstances(),
    });
    visitedUnit.resetState();
  },
};
