import * as R from 'ramda';
import React, { useCallback, useMemo } from 'react';

import { VerticallyMovableElement, WidgetNumberMark } from 'components';
import { QuizWriteContext } from 'features/quiz/Constructor/QuizWriteContext';
import {
  makePrimaryUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  PrimaryStateUnit,
} from 'utils/State';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ConstructorFormSection } from '../../../subfeatures';
import {
  StoreCachedInstances,
  ConstructorCachedInstance,
  InstanceView,
  CachedQuestionInstance,
  QuestionKey,
  QuestionPath,
} from '../types';
import { QuestionContext } from './Context';
import * as ForQuestion from './ForQuestion';
import * as ForQuestionSelection from './ForQuestionSelection';

type Props = {
  instanceView: VerticallyMovableElement.Element<InstanceView>;
  prevInstance?: ConstructorCachedInstance;
  cachedStoreInstancesUnit: PrimaryStateUnit<StoreCachedInstances>;
  onQuestionTypeChange?(
    instance: CachedQuestionInstance,
    questionKey: QuestionKey,
    setQuestionType: (questionKey: QuestionKey) => void,
  ): void;
};

function getQuestionNumber(path: QuestionPath) {
  return path.index + 1;
}

const makeHasErrorUnit = (instance: CachedQuestionInstance) =>
  makeMappingUnitFromUnit(
    makeDerivedUnit(instance.activeQuestionKeyUnit).getUnit(
      key => instance.instancesCache[key].hasErrorUnit,
    ),
  );

function Constructor({
  instanceView,
  prevInstance,
  cachedStoreInstancesUnit,
  onQuestionTypeChange,
}: Props) {
  const { path } = instanceView.value;
  const questionNumber = getQuestionNumber(path);

  const { saveQuiz } = useRequiredContext(QuizWriteContext);

  const Title = useMemo(
    () =>
      WidgetNumberMark.makeComponent({
        value: questionNumber,
      }),
    [questionNumber],
  );

  const handleWidgetDelete = useCallback(() => {
    cachedStoreInstancesUnit.setState(prev => {
      const lens = R.lensPath<StoreCachedInstances, 'instances'>(['instances']);

      return R.over(
        lens,
        instances => R.without([instanceView.value.instance], instances),
        prev,
      );
    });
    if (instanceView.value.instance.kind !== 'select-instance-kind') {
      saveQuiz();
    }
  }, [cachedStoreInstancesUnit, instanceView.value.instance, saveQuiz]);

  const handleMoveWidgetPrev = useCallback(() => {
    cachedStoreInstancesUnit.setState(prev => {
      if (path.index === 0) return prev;

      const next: StoreCachedInstances = {
        ...prev,
        instances: R.move(path.index, path.index - 1, prev.instances),
      };

      saveQuiz();

      return next;
    });
  }, [cachedStoreInstancesUnit, path.index, saveQuiz]);

  const handleMoveWidgetNext = useCallback(() => {
    cachedStoreInstancesUnit.setState(prev => {
      if (path.index === prev.instances.length - 1) return prev;

      const next: StoreCachedInstances = {
        ...prev,
        instances: R.move(path.index, path.index + 1, prev.instances),
      };

      saveQuiz();

      return next;
    });
  }, [cachedStoreInstancesUnit, path.index, saveQuiz]);

  const hasErrorUnit = useMemo(() => {
    // TODO make it better
    return instanceView.value.instance.kind === 'select-instance-kind'
      ? makePrimaryUnit(false)
      : makeHasErrorUnit(instanceView.value.instance);
  }, [instanceView.value.instance]);

  const hasError = hasErrorUnit.useState();

  const prevWidgetHasErrorUnit = useMemo(() => {
    if (prevInstance?.kind === 'question-instance') {
      return makeHasErrorUnit(prevInstance);
    }

    return makePrimaryUnit(false);
  }, [prevInstance]);
  const prevWidgetHasError = prevWidgetHasErrorUnit.useState();

  return (
    <VerticallyMovableElement.Component element={instanceView}>
      <ConstructorFormSection.Component
        hasError={hasError}
        prevHasError={prevWidgetHasError}
      >
        <QuestionContext.Provider
          onDelete={handleWidgetDelete}
          onMovePrev={handleMoveWidgetPrev}
          onMoveNext={handleMoveWidgetNext}
        >
          {(() => {
            switch (instanceView.value.instance.kind) {
              case 'select-instance-kind':
                return (
                  <ForQuestionSelection.Component
                    Title={Title}
                    path={path}
                    cachedStoreInstancesUnit={cachedStoreInstancesUnit}
                  />
                );
              default:
                return (
                  <ForQuestion.QuestionContextProvider
                    cachedInstance={instanceView.value.instance}
                  >
                    <ForQuestion.Component
                      Title={Title}
                      onQuestionTypeChange={onQuestionTypeChange}
                    />
                  </ForQuestion.QuestionContextProvider>
                );
            }
          })()}
        </QuestionContext.Provider>
      </ConstructorFormSection.Component>
    </VerticallyMovableElement.Component>
  );
}

export const Component = React.memo(Constructor);
