import { Filter } from 'features/project/Constructor/subfeatures';
import * as M from 'types/serverModels';

import { ConstructorWidgetConfigForFindingsWidget } from '../../types';
import { makeSharedInstancePart } from '../makeSharedInstancePart';
import { SharedConstructorArgs } from '../shared/SharedConstructorArgs';
import * as View from './View';
import { DataListInstance } from './types';

export function makeServerWidget(
  filter: Filter.State,
): Partial<M.DataListWidget> {
  return {
    type: 'datalist',
    descriptor: {
      filter: Filter.makeServerFilterFromState(filter),
    },
  };
}

function makeInstance(args: SharedConstructorArgs): DataListInstance {
  return {
    kind: 'dataList',
    id: args.id,
    makeServerWidget() {
      return makeServerWidget(this.filter);
    },
    ...makeSharedInstancePart(args),
  };
}

export const constructorWidgetConfig: ConstructorWidgetConfigForFindingsWidget<
  'dataList',
  DataListInstance,
  typeof makeInstance
> = {
  key: 'dataList',
  icon: 'dataList',
  makeInstance,
  View: View.Component,
  viewHeight: 500,
};

export type { DataListInstance };
