import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { makeQuestionIDToQuestionTextUnit } from 'utils/models/Project';

import { Props } from './types';

export const makePropsUnits = (
  questions: M.Question[],
  types: M.Question['type'][],
  questionId?: string,
  optional: boolean = false,
): Props => {
  return {
    questionIDToQuestionTextUnit: makeQuestionIDToQuestionTextUnit(questions),
    selectedQuestionIDState: makeFormElementState<string | null>(
      questionId || null,
    ),
    questionIDsUnit: makePrimaryUnit(
      optional
        ? [
            null,
            ...questions.filter(x => types.includes(x.type)).map(x => x.uuid),
          ]
        : questions.filter(x => types.includes(x.type)).map(x => x.uuid),
    ),
  };
};
