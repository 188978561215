import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { Button } from 'components';
import {
  getProjectPageRootParams,
  ProjectPageRouteTree,
  routeTree,
} from 'pages/routeTree';
import { I18n } from 'services';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../i18n.json';
import { ProjectWriteContext } from '../../ProjectWriteContext';

const b = block('preview-button');

type Props = {
  projectPageRouteTree?: ProjectPageRouteTree;
};

function Preview({ projectPageRouteTree }: Props) {
  const history = useHistory();
  const text = I18n.useText(i18nData);

  const { callStateUnit, saveProjectWithoutDebounce } =
    useRequiredContext(ProjectWriteContext);

  const handleButtonClick = useCallback(() => {
    saveProjectWithoutDebounce().then(serverProject => {
      if (!serverProject) return;

      if (projectPageRouteTree) {
        history.push(
          projectPageRouteTree.tree.PROJECT.general.getPath({
            routeParams: {
              ...getProjectPageRootParams(projectPageRouteTree),
              LANG: I18n.activeLangStateUnit.getState(),
              PROJECT: serverProject.uuid,
            },
          }),
        );
      } else {
        history.push(
          routeTree.LANG.project.list.PROJECT.general.getPath({
            routeParams: {
              LANG: I18n.activeLangStateUnit.getState(),
              PROJECT: serverProject.uuid,
            },
          }),
        );
      }
    });
  }, [history, projectPageRouteTree, saveProjectWithoutDebounce]);

  return (
    <Button.Component
      className={b()}
      type="button"
      variant="outlined"
      color="secondary"
      callStateUnit={callStateUnit}
      onClick={handleButtonClick}
    >
      {text.formContainer.previewButtonLabel}
    </Button.Component>
  );
}

export const Component = React.memo(Preview);
