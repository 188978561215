import React, { useCallback, useMemo } from 'react';

import { Button, TextInput } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import i18nData from 'features/project/Constructor/i18n.json';
import {
  WidgetDeleteControl,
  WidgetLayout,
  WidgetNavigationControls,
  WidgetSettingsControl,
  WidgetFullscreenControl,
  Filter,
} from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { usePrimaryUnit } from 'utils/State';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { useStateHash } from '../../../InputDataForm/StateHash';
import { WidgetKey } from '../../types';
import { chartWidgetKeys } from '../../widgets';
import { ConstructorWidgetContext } from '../Context';
import { FindingsConstructorWidgetContext } from '../FindingsConstructorWidgetContext';
import { FullscreenUnitContext } from './FullscreenUnitContext';
import './style.scss';

type Props = {};

const b = block('constructor-widget-for-widget');

function ControlsBar({
  ExpandTriggerArea,
  panelExpandedUnit,
}: WidgetLayout.ControlsBarProps) {
  const { onMoveNext, onMovePrev, onDelete } = useRequiredContext(
    ConstructorWidgetContext,
  );

  const { fullscreenUnit } = useRequiredContext(FullscreenUnitContext);

  const handleFullscreenEnter = useCallback(() => {
    fullscreenUnit.setState(true);
  }, [fullscreenUnit]);

  return (
    <>
      <WidgetNavigationControls.Component
        onMoveNext={onMoveNext}
        onMovePrev={onMovePrev}
      />
      <ExpandTriggerArea>
        <WidgetSettingsControl.Component
          settingsAreExpandedUnit={panelExpandedUnit}
        />
      </ExpandTriggerArea>
      <WidgetFullscreenControl.Component
        variant="on"
        onClick={handleFullscreenEnter}
      />
      <WidgetDeleteControl.Component onDelete={onDelete} />
    </>
  );
}

function ConstructorWidgetForWidget({}: Props) {
  const { getProjectUUID } = useRequiredContext(ConstructorConfigContext);
  const { constructorWidget, stateInstance } = useRequiredContext(
    FindingsConstructorWidgetContext,
  );

  const { View, DataSettings, Layers, viewHeight } = constructorWidget;

  const fullscreenUnit = usePrimaryUnit<boolean>(false);
  const fullscreen = fullscreenUnit.useState();

  const WidgetDataSettings = useMemo(() => {
    if (!DataSettings) return;

    return React.memo(() => {
      return <DataSettings instance={stateInstance} />;
    });
  }, [DataSettings, stateInstance]);

  const WidgetView = useMemo(() => {
    return React.memo(() => {
      const shouldEmulateDataUnit = usePrimaryUnit<boolean>(false);

      return (
        <div className={b('view')}>
          <View
            instance={stateInstance}
            shouldEmulateDataUnit={shouldEmulateDataUnit}
            useEmulationSeed={useStateHash}
          />
        </div>
      );
    });
  }, [View, stateInstance]);

  const WidgetLayers = useMemo(() => {
    if (!Layers) return;

    return React.memo(() => {
      return <Layers instance={stateInstance} />;
    });
  }, [Layers, stateInstance]);

  const ViewOverline = useMemo(() => {
    return React.memo(() => {
      const titleFieldPlaceholder =
        I18n.useText(i18nData).steps.findingsSettings
          .widgetTitleFieldPlaceholder;

      return (
        <TextInput.Component
          formElementState={stateInstance.title.formElementState}
          placeholder={titleFieldPlaceholder}
        />
      );
    });
  }, [stateInstance.title.formElementState]);

  const isChartWidget = (chartWidgetKeys as WidgetKey[]).includes(
    constructorWidget.key,
  );

  const text = I18n.useText(i18nData).steps.findingsSettings;
  const title = stateInstance.title.formElementState.units.value.useState();

  const widgetType = isChartWidget
    ? text.chartWidgetTitle
    : text.widgets[constructorWidget.key].name;

  const Underline = useMemo(() => {
    return React.memo(() => {
      const text =
        I18n.useText(i18nData).steps.findingsSettings.widgetModeChangeButton;

      const { mode: modeUnit } = stateInstance;

      const mode = modeUnit.useState();

      const { saveProject } = useRequiredContext(ProjectWriteContext);

      const handleClick = useCallback(() => {
        if (modeUnit.getState() === 'edit') {
          if (stateInstance.validate()) {
            modeUnit.setState('preview');
            saveProject();
          }
        } else {
          modeUnit.setState('edit');
        }
      }, [modeUnit, saveProject]);

      return (
        <Button.Component
          className={b('change-mode-button')}
          type="button"
          onClick={handleClick}
          variant={mode === 'edit' ? 'contained' : 'outlined'}
          color={mode === 'edit' ? 'accent-2' : 'secondary'}
        >
          {mode === 'edit' ? text.saveLabel : text.editLabel}
        </Button.Component>
      );
    });
  }, [stateInstance]);

  const { mode: modeUnit, id } = stateInstance;

  const mode = modeUnit.useState();

  const settingsAreVisibleUnit = WidgetLayout.useSettingsAreVisibleUnit(true);

  return (
    <Filter.FullscreenContext.Provider fullscreen={fullscreen}>
      <FullscreenUnitContext.Provider fullscreenUnit={fullscreenUnit}>
        <WidgetLayout.Component
          id={id}
          ControlsBar={ControlsBar}
          DataSettings={WidgetDataSettings}
          View={WidgetView}
          ViewOverline={mode === 'edit' ? ViewOverline : undefined}
          Underline={Underline}
          Layers={WidgetLayers}
          filterState={stateInstance.filter}
          fullScreenUnit={fullscreenUnit}
          title={title}
          widgetType={widgetType}
          titleMode={mode === 'edit' ? 'widget-type' : 'regular'}
          settingsAreVisibleUnit={settingsAreVisibleUnit}
          viewHeight={viewHeight}
          projectUUID={getProjectUUID() || id}
        />
      </FullscreenUnitContext.Provider>
    </Filter.FullscreenContext.Provider>
  );
}

export const Component = React.memo(ConstructorWidgetForWidget);
