import { useMemo } from 'react';
import ReactDOM from 'react-dom';

import { Device } from 'services';
import { makePrimaryUnit } from 'utils/State';
import { block } from 'utils/classname';

import * as Desktop from './Desktop';
import * as Mobile from './Mobile';

export * as Desktop from './Desktop';
export * as Header from './Header';
export * as Layout from './Layout';
export * as Footer from './Footer';
export * as InfoText from './InfoText';

export type { Size } from './types';

const b = block('modal-portal');

export type Props = Desktop.Props & {
  desktopOnly?: boolean;
  mobileOnly?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export function makeIsOpenUnit(initialState: boolean = false) {
  return makePrimaryUnit<boolean>(initialState);
}

export function useIsOpenUnit(initialState: boolean = false) {
  return useMemo(() => makeIsOpenUnit(initialState), [initialState]);
}

function Modal(props: React.PropsWithChildren<Props>) {
  const device = Device.shortUnit.useState();

  const isOpen = props.isOpenUnit.useState();

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={b()} onClick={props.onClick}>
      {(() => {
        if ((device === 'mobile' || props.mobileOnly) && !props.desktopOnly) {
          return (
            <Mobile.Component
              isOpenUnit={props.isOpenUnit}
              Header={props.Header}
              onClose={props.onClose}
              size={props.size}
              className={props.className}
            >
              {props.children}
            </Mobile.Component>
          );
        }
        return <Desktop.Component {...props} />;
      })()}
    </div>,
    document.body,
  );
}

export const Component = Modal;
