import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { validate } from 'uuid';

import { Preloader } from 'components';
import { API, Page, Routing } from 'services';
import * as M from 'types/serverModels';

import { GroupRouteTree, routeTree } from '../routeTree';
import * as About from './About';
import * as Discussion from './Discussion';
import * as Monitoring from './Monitoring';
import * as Rating from './Rating';
import * as Settings from './Settings';
import * as features from './features';

function Group() {
  const [group, setGroup] = useState<M.ServerGroup | null>(null);

  const callStateUnit = API.services.serverGroupRead.useCallStateUnit();
  const call = API.services.serverGroupRead.useCall(callStateUnit);

  const params = routeTree.LANG.group.GROUP.useRouteParams();
  const groupUUID = params ? params.group : null;

  const isSettingsCurr = Routing.useCurrentLocationIsMatchingTo(
    routeTree.LANG.group.GROUP.settings.getPath(),
  );

  const groupRouteTree: GroupRouteTree = useMemo(() => {
    return {
      kind: 'group',
      tree: routeTree.LANG['group'].GROUP,
    };
  }, []);

  const getGroup = useCallback(() => {
    if (groupUUID && validate(groupUUID)) {
      call({ uuid: groupUUID, licenses: 1, rand: Math.random() });
    }
  }, [call, groupUUID]);

  useEffect(() => {
    getGroup();
  }, [getGroup]);

  useEffect(() => {
    return callStateUnit.subscribe({
      name: 'loading-group',
      callback: callState => {
        switch (callState.kind) {
          case 'successful': {
            setGroup(callState.data);
            break;
          }
        }
      },
    });
  }, [callStateUnit]);

  return (
    <>
      {group !== null ? (
        <>
          {!isSettingsCurr && <features.GroupHeader.Component group={group} />}
          {!isSettingsCurr && <features.Tabs.Component group={group} />}
          <About.Component group={group} />
          <Discussion.Component group={group} />
          <Rating.Component group={group} />
          <Monitoring.Component groupRouteTree={groupRouteTree} group={group} />
          <Settings.Component group={group} getGroup={getGroup} />
        </>
      ) : (
        <Preloader.Component size="m" />
      )}
    </>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.group.GROUP.getPath(),
  routeProps: { exact: false },
  scrollTo: 'top-on-mount',
  Component: React.memo(Group),
  features: Object.values(features).map(x => x.feature),
});
