import { PartialDeep } from 'type-fest';

import { Filter } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonNull } from 'utils/validators';

import {
  ConstructorWidgetConfigForFindingsWidget,
  QuestionID,
  WidgetMode,
} from '../../types';
import { makeSharedInstancePart } from '../makeSharedInstancePart';
import { SharedConstructorArgs } from '../shared/SharedConstructorArgs';
import * as DataSettings from './DataSettings';
import * as View from './View';
import { FilesListInstance } from './types';

type ServerWidgetConstructorArgs = {
  selectedQuestionIDState: FormElementState<QuestionID | null>;
  filter: Filter.State;
};

export function makeServerWidget(
  args: ServerWidgetConstructorArgs,
): PartialDeep<M.FilesListWidget> {
  const { filter, selectedQuestionIDState } = args;
  const selectedQuestionID = selectedQuestionIDState.units.value.getState();

  return {
    type: 'fileslist',
    descriptor: {
      filter: Filter.makeServerFilterFromState(filter),
      questionId: selectedQuestionID || undefined,
    },
  };
}

function makeInstance({
  initialQuestionID = null,
  ...sharedArgs
}: {
  id: string;
  initialQuestionID?: QuestionID | null;
  initialFilterState?: Filter.InitialState;
  initialMode?: WidgetMode;
} & SharedConstructorArgs): FilesListInstance {
  const questionIDState = makeFormElementState<null | QuestionID>(
    initialQuestionID,
    [nonNull(I18n.constants.emptyReference)],
  );

  return {
    ...makeSharedInstancePart({
      ...sharedArgs,
      formEntitiesUnit: makePrimaryUnit([questionIDState]),
    }),
    kind: 'filesList',
    id: sharedArgs.id,
    questionID: questionIDState,
    makeServerWidget() {
      return makeServerWidget({
        filter: this.filter,
        selectedQuestionIDState: questionIDState,
      });
    },
  };
}

export const constructorWidgetConfig: ConstructorWidgetConfigForFindingsWidget<
  'filesList',
  FilesListInstance,
  typeof makeInstance
> = {
  key: 'filesList',
  icon: 'filesList',
  makeInstance,
  DataSettings: DataSettings.Component,
  View: View.Component,
  viewHeight: 500,
};

export type { FilesListInstance };
