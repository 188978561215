import React, { useCallback, useEffect } from 'react';

import { Button } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { ClassificationsFetcher } from 'features/project/Constructor/subfeatures';
import { API, I18n } from 'services';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { classificationsUnit } from '../../units/inputState';
import * as Item from './Item';
import * as ClassificationsModal from './Modal';
import './style.scss';

const b = block('classifications-section');

type Props = {};

const callStateUnit = API.services.classificationPaths.makeCallStateUnit();

function Rubrics({}: Props) {
  const text =
    I18n.useText(i18nData).steps.projectAnnouncement.sections.classifications;
  const call = API.services.classificationPaths.useCall(callStateUnit);
  const callState = callStateUnit.useState();

  const classifications = classificationsUnit.useState();

  const handleButtonClick = useCallback(() => {
    ClassificationsModal.isOpenUnit.setState(true);
  }, []);

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  useEffect(() => {
    return classificationsUnit.subscribe({
      name: 'classifications-changed',
      callback: saveProject,
    });
  }, [saveProject]);

  useEffect(() => {
    switch (callState.kind) {
      case 'initial': {
        if (classifications.length > 0) {
          call({ term: classifications });
        }
        break;
      }
      case 'successful': {
        const allPathsExist = classifications.every(uuid =>
          callState.data.some(x => x.path[x.path.length - 1].uuid === uuid),
        );
        if (!allPathsExist) {
          call({ term: classifications });
        }
        break;
      }
    }
  }, [call, callState, classifications]);

  useEffect(() => {
    Object.entries(ClassificationsModal.stateUnit.getState()).forEach(
      ([key, x]) => x.value.units.value.setState(classifications.includes(key)),
    );
  }, [classifications]);

  return (
    <div className={b()}>
      {API.renderCallState(callState, {
        successful: state => (
          <ul className={b('list')}>
            {state.data
              .filter(x =>
                classifications.includes(x.path[x.path.length - 1].uuid),
              )
              .map(x => (
                <Item.Component key={x.path[x.path.length - 1].uuid} data={x} />
              ))}
          </ul>
        ),
      })}
      <ClassificationsModal.Component />
      <ClassificationsFetcher.Component
        initialValues={classifications}
        stateUnit={ClassificationsModal.stateUnit}
      />
      <Button.Component
        callStateUnit={ClassificationsFetcher.callStateUnit}
        type="button"
        onClick={handleButtonClick}
      >
        {text.button}
      </Button.Component>
    </div>
  );
}

export const Component = React.memo(Rubrics);
