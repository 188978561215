import React, { useCallback } from 'react';

import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import {
  ConstructorFormSection,
  ConstructorStepSection,
  TextEditor,
} from 'features/project/Constructor/subfeatures';
import { getProjectLanguage } from 'features/project/Constructor/utils';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../i18n.json';
import { ProjectWriteContext } from '../../ProjectWriteContext';
import { Step } from '../../types';
import * as Preview from './Preview';
import './style.scss';
import {
  equipmentUnit,
  errorsUnit,
  hasSomeInputUnit,
  progressInvariantUnitsUnit,
  safetyProcedureUnit,
  visitedUnit,
  workProcessUnit,
} from './units';

const b = block('instruction-step');

type Props = {};

function Instruction({}: Props) {
  const { mode } = useRequiredContext(ConstructorConfigContext);
  const fields = I18n.useText(i18nData).steps.instruction.fields;

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleBlur = useCallback(() => {
    saveProject();
  }, [saveProject]);

  return (
    <div className={b()}>
      <ConstructorFormSection.Component>
        <ConstructorStepSection.Component
          title={fields.workProcess.label}
          tooltip={fields.workProcess.tooltip}
        >
          <TextEditor.Component
            formElementState={workProcessUnit}
            placeholder={fields.workProcess.placeholder}
            errorRows={1}
            onBlur={handleBlur}
          />
        </ConstructorStepSection.Component>
        <ConstructorStepSection.Component
          title={fields.equipment.label}
          tooltip={fields.equipment.tooltip}
        >
          <TextEditor.Component
            formElementState={equipmentUnit}
            placeholder={fields.equipment.placeholder}
            onBlur={handleBlur}
          />
        </ConstructorStepSection.Component>
        {mode === 'full' && (
          <ConstructorStepSection.Component
            title={fields.safetyProcedure.label}
            tooltip={fields.safetyProcedure.tooltip}
          >
            <TextEditor.Component
              formElementState={safetyProcedureUnit}
              placeholder={fields.safetyProcedure.placeholder}
              onBlur={handleBlur}
            />
          </ConstructorStepSection.Component>
        )}
      </ConstructorFormSection.Component>
    </div>
  );
}

const Component = React.memo(Instruction);

export const step: Step = {
  key: 'instruction',
  Form: Component,
  Preview: Preview.Component,
  errorsUnit,
  visitedUnit,
  progressInvariantUnitsUnit,
  hasSomeInputUnit,
  getProjectData: ({ serverProject }) => {
    const lang = getProjectLanguage();

    return {
      protocol: I18n.makeMultilangFormState(
        workProcessUnit,
        serverProject?.protocol,
      ).getMergedMultilingString(lang),

      equipment: I18n.makeMultilangFormState(
        equipmentUnit,
        serverProject?.equipment,
      ).getMergedMultilingString(lang),

      safety: I18n.makeMultilangFormState(
        safetyProcedureUnit,
        serverProject?.safety,
      ).getMergedMultilingString(lang),
    };
  },
  fillFromExistingProject: ({ language, project }) => {
    const t = (str: M.MultilingString) =>
      I18n.getMultilingTranslation(language, str);

    if (project.protocol) {
      workProcessUnit.units.value.setState(t(project.protocol));
    }
    // TODO fill work process

    if (project.equipment) {
      equipmentUnit.units.value.setState(t(project.equipment));
    }

    if (project.safety) {
      safetyProcedureUnit.units.value.setState(t(project.safety));
    }
  },
  resetState: () => {
    visitedUnit.resetState();
  },
  resetStateAfterDelay: () => {
    equipmentUnit.formNode.reset();
    safetyProcedureUnit.formNode.reset();
    workProcessUnit.formNode.reset();
  },
};
