import { Filter } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonNull } from 'utils/validators';

import {
  ConstructorWidgetConfigForFindingsWidget,
  QuestionID,
} from '../../types';
import { makeSharedInstancePart } from '../makeSharedInstancePart';
import { SharedConstructorArgs } from '../shared/SharedConstructorArgs';
import * as DataSettings from './DataSettings';
import * as View from './View';
import { makeServerWidget } from './makeServerWidget';
import { GalleryInstance } from './types';

export { makeServerWidget };

export type ServerWidgetConstructorArgs = {
  selectedQuestionIDState: FormElementState<QuestionID | null>;
  filter: Filter.State;
};

function makeInstance({
  initialQuestionID = null,
  ...sharedArgs
}: {
  initialQuestionID?: QuestionID | null;
} & SharedConstructorArgs): GalleryInstance {
  const questionIDState = makeFormElementState<null | QuestionID>(
    initialQuestionID,
    [nonNull(I18n.constants.emptyReference)],
  );

  return {
    kind: 'gallery',
    id: sharedArgs.id,
    questionID: questionIDState,
    makeServerWidget() {
      return makeServerWidget({
        filter: this.filter,
        selectedQuestionIDState: questionIDState,
      });
    },
    ...makeSharedInstancePart({
      ...sharedArgs,
      formEntitiesUnit: makePrimaryUnit([questionIDState]),
    }),
  };
}

export const constructorWidgetConfig: ConstructorWidgetConfigForFindingsWidget<
  'gallery',
  GalleryInstance,
  typeof makeInstance
> = {
  key: 'gallery',
  icon: 'gallery',
  makeInstance,
  View: View.Component,
  DataSettings: DataSettings.Component,
  viewHeight: 500,
};

export type { GalleryInstance };
