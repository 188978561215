import * as R from 'ramda';
import { useEffect, useMemo } from 'react';
import React from 'react';

import { API } from 'services';
import { makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit, PrimaryStateUnit } from 'utils/State';

import * as SelectedFetcher from './SelectedFetcher';
import { ClassificationsState } from './types';

type Props = {
  initialValues?: string[];
  stateUnit: PrimaryStateUnit<ClassificationsState>;
};

export const callStateUnit =
  API.services.classificationTermLoad.makeCallStateUnit();

function Fetcher({ initialValues, stateUnit }: Props) {
  const call = API.services.classificationTermLoad.useCall(callStateUnit);
  const termsCallState = callStateUnit.useState();
  const pathsCallState = SelectedFetcher.callStateUnit.useState();

  const notLoaded = useMemo(() => {
    if (termsCallState.kind === 'successful' && Array.isArray(initialValues)) {
      return R.differenceWith((a, b) => a === b, initialValues, [
        ...Object.keys(stateUnit.getState()),
        ...termsCallState.data.map(x => x.uuid),
      ]);
    }
    return null;
  }, [initialValues, stateUnit, termsCallState]);

  useEffect(() => {
    if (termsCallState.kind === 'initial') {
      call({ classification: process.env.RAZZLE_SUBJECT_CLASSIFIER });
    }
  }, [call, termsCallState.kind]);

  useEffect(() => {
    const isRequestsCompleted = Array.isArray(initialValues)
      ? termsCallState.kind === 'successful' &&
        pathsCallState.kind === 'successful'
      : termsCallState.kind === 'successful';
    const paths =
      pathsCallState.kind === 'successful'
        ? pathsCallState.data.flatMap(x => x.path)
        : [];

    if (isRequestsCompleted) {
      stateUnit.setState(prevState => {
        const keys = Object.keys(prevState);

        return {
          ...prevState,
          ...R.uniqWith(
            (a, b) => a.uuid === b.uuid,
            [...(termsCallState as any).data, ...paths],
          )
            .filter(x => !keys.includes(x.uuid))
            .reduce(
              (acc, x) => ({
                ...acc,
                [x.uuid]: {
                  label: x.caption,
                  value: makeFormElementState(initialValues?.includes(x.uuid)),
                  extended: makePrimaryUnit(false),
                  children: makePrimaryUnit([]),
                },
              }),
              {},
            ),
        };
      });
    }
  }, [initialValues, pathsCallState, stateUnit, termsCallState]);

  return (
    <>
      {termsCallState.kind === 'successful' && notLoaded && (
        <SelectedFetcher.Component terms={notLoaded} />
      )}
    </>
  );
}

export const Component = React.memo(Fetcher);
