import * as M from 'types/serverModels';
import { isValidUrl } from 'utils/url';

export function answerHasImages(
  questionAnswer: unknown,
): questionAnswer is M.Image | M.Image[] {
  return (
    (typeof questionAnswer === 'object' &&
      questionAnswer !== null &&
      'thumb' in questionAnswer &&
      typeof questionAnswer.thumb === 'string') ||
    (Array.isArray(questionAnswer) &&
      typeof questionAnswer[0] === 'object' &&
      questionAnswer[0] !== null &&
      'thumb' in questionAnswer[0] &&
      typeof questionAnswer[0].thumb === 'string')
  );
}

export function answerHasText(
  questionAnswer: unknown,
): questionAnswer is string {
  return typeof questionAnswer === 'string';
}

export function answerHasVideo(
  questionAnswer: unknown,
): questionAnswer is M.VideoFromHosting {
  return (
    typeof questionAnswer === 'object' &&
    questionAnswer !== null &&
    'host' in questionAnswer &&
    (questionAnswer.host === 'youtube' || questionAnswer.host === 'vimeo')
  );
}

export function answerHasFile(
  questionAnswer: unknown,
): questionAnswer is string {
  return isValidUrl(questionAnswer);
}
