import { makeFeature } from 'services/Feature';
import { makePrimaryUnit } from 'utils/State';

import i18nData from './i18n.json';
import * as subfeatures from './subfeatures';
import { ClassificationsState } from './types';

export const makeStateUnit = () => makePrimaryUnit<ClassificationsState>({});

export const feature = makeFeature({
  name: 'Classifications',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "classifications" */
      './Component'
    ),
  i18nData,
  i18nSharedReferences: null,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export const Component = feature.Component;
