import { MathJax } from 'better-react-mathjax';
import React from 'react';

import { RoundedListItemMark } from 'components';
import { Type } from 'features/Questions/types';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';
import { parseHTML } from 'utils/react';

import i18nData from '../../i18n.json';
import { QuestionExplanation, QuestionText } from '../../subfeatures';
import './style.scss';

const b = block('question-layout');

type Props = React.PropsWithChildren<{
  title: string;
  description: string;
  image?: string;
  variant: 'contained' | 'outlined';
  answer?: string;
  num: number;
  type: Type;
  isEmpty: boolean;
  isOptional: boolean;
  isAutocomplete: boolean;
  result?: M.QuizAnswerResult;
}>;

function QuestionLayout({
  title,
  description,
  image,
  num,
  type,
  answer,
  variant,
  isOptional,
  isEmpty,
  isAutocomplete,
  result,
  children,
}: Props) {
  const text = I18n.useText(i18nData);

  const getColor = () => {
    switch (result?.eval) {
      case 'noeval':
      case 'correct':
        return 'accent-2';
      case 'semicorrect':
      case 'incorrect':
        return 'error';
      default:
        return 'accent-2';
    }
  };

  const getVariant = () => {
    switch (result?.eval) {
      case 'incorrect':
      case 'correct':
        return 'contained';
      case 'noeval':
      case 'semicorrect':
        return 'outlined';
      default:
        return variant;
    }
  };

  return (
    <section className={b()}>
      <div className={b('container')}>
        <div className={b('header')}>
          <div className={b('number')}>
            <RoundedListItemMark.Component
              color={getColor()}
              variant={getVariant()}
            >
              {num}
            </RoundedListItemMark.Component>
          </div>
          <QuestionText.Component questionText={title} />
        </div>
        <div className={b('content')}>
          {description && (
            <QuestionExplanation.Component
              questionExplanation={description}
              kind={type === 'questionnaire' ? 'text' : 'html'}
              className={b('description')}
            />
          )}
          {image && <img className={b('image')} src={image} alt="question" />}
          {isEmpty ? (
            <span className={b('note')}>{text.emptyLabel}</span>
          ) : (
            children
          )}
          {(isOptional || isAutocomplete) && (
            <span className={b('note')}>
              {isOptional && !isAutocomplete && text.optionalLabel}
              {isAutocomplete && text.autocompleteLabel}
            </span>
          )}
          {result && result.max > 0 && (
            <p className={b('score')}>
              {text.scoreLabel}: {result.achieved.toFixed(2)} /{' '}
              {result.max.toFixed(2)}
            </p>
          )}
          {answer && (
            <MathJax className={b('answer')} dynamic>
              {parseHTML(answer)}
            </MathJax>
          )}
        </div>
      </div>
    </section>
  );
}
export const Component = React.memo(QuestionLayout);
