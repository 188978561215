import * as R from 'ramda';
import React, { useCallback, useMemo } from 'react';

import { VerticallyMovableElement } from 'components';
import { ConstructorFormSection } from 'features/project/Constructor/subfeatures';
import {
  AbstractStateUnit,
  makeDerivedUnit,
  makePrimaryUnit,
} from 'utils/State';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ProjectWriteContext } from '../../../ProjectWriteContext';
import { ConstructorWidgetInstance } from '../types';
import { instancesUnit } from '../units';
import { orderingModeUnit } from '../units/orderingMode';
import {
  FindingsWidgetInstance,
  widgetKeyToFindingsConstructorWidget,
} from '../widgets';
import * as CollapsedWidget from './Collapsed';
import { ConstructorWidgetContext } from './Context';
import { FindingsConstructorWidgetContext } from './FindingsConstructorWidgetContext';
import * as ConstructorWidgetForWidget from './ForWidget';
import * as ConstructorWidgetForWidgetSelection from './ForWidgetSelection';

type Props = {
  constructorWidgetInstance: VerticallyMovableElement.Element<ConstructorWidgetInstance>;
  prevWidgetHasErrorUnit?: AbstractStateUnit<boolean>;
};

function ConstructorWidget({
  constructorWidgetInstance,
  prevWidgetHasErrorUnit = makePrimaryUnit<boolean>(false),
}: Props) {
  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleWidgetDelete = useCallback(() => {
    instancesUnit.setState(prev => {
      return R.without([constructorWidgetInstance.value], prev);
    });
    if (constructorWidgetInstance.value.kind !== 'select-instance-kind') {
      saveProject();
    }
  }, [constructorWidgetInstance.value, saveProject]);

  const handleMoveWidgetPrev = useCallback(() => {
    instancesUnit.setState(prev => {
      const index = prev.findIndex(x => x === constructorWidgetInstance.value);
      const next = R.move(index, index - 1, prev);

      saveProject();

      return next;
    });
  }, [constructorWidgetInstance.value, saveProject]);

  const handleMoveWidgetNext = useCallback(() => {
    instancesUnit.setState(prev => {
      const index = prev.findIndex(x => x === constructorWidgetInstance.value);
      const next = R.move(index, index + 1, prev);

      saveProject();

      return next;
    });
    saveProject();
  }, [constructorWidgetInstance.value, saveProject]);

  const orderingMode = orderingModeUnit.useState();

  const hasErrorUnit = useMemo(() => {
    // TODO make it better
    return constructorWidgetInstance.value.kind === 'select-instance-kind'
      ? makeDerivedUnit(makePrimaryUnit(false)).getUnit(x => x)
      : constructorWidgetInstance.value.hasErrorUnit;
  }, [constructorWidgetInstance]);

  const hasError = hasErrorUnit.useState();
  const prevWidgetHasError = prevWidgetHasErrorUnit.useState();

  return (
    <VerticallyMovableElement.Component element={constructorWidgetInstance}>
      <ConstructorFormSection.Component
        collapsed={orderingMode === 'on'}
        hasError={hasError}
        prevHasError={prevWidgetHasError}
      >
        <ConstructorWidgetContext.Provider
          stateInstance={
            constructorWidgetInstance.value as FindingsWidgetInstance
          }
          onDelete={handleWidgetDelete}
          onMovePrev={handleMoveWidgetPrev}
          onMoveNext={handleMoveWidgetNext}
        >
          {(() => {
            if (orderingMode === 'on') {
              return <CollapsedWidget.Component />;
            }

            if (
              constructorWidgetInstance.value.kind === 'select-instance-kind'
            ) {
              return <ConstructorWidgetForWidgetSelection.Component />;
            }

            const constructorWidget =
              widgetKeyToFindingsConstructorWidget[
                constructorWidgetInstance.value.kind
              ];

            return (
              <FindingsConstructorWidgetContext.Provider
                constructorWidget={constructorWidget}
                stateInstance={constructorWidgetInstance.value}
              >
                <ConstructorWidgetForWidget.Component />
              </FindingsConstructorWidgetContext.Provider>
            );
          })()}
        </ConstructorWidgetContext.Provider>
      </ConstructorFormSection.Component>
    </VerticallyMovableElement.Component>
  );
}

export const Component = React.memo(ConstructorWidget);
