import React from 'react';

import { block } from 'utils/classname';
import { parseHTML } from 'utils/react';

export type Props = {
  useLabel(): string;
};

const b = block('chip');

function Label({ useLabel }: Props) {
  const label = useLabel();

  return (
    <div className={b('label')} title={label}>
      {parseHTML(label)}
    </div>
  );
}

const Component = React.memo(Label);

export function makeLabel(props: Props) {
  return () => <Component {...props} />;
}
