import React, { useCallback } from 'react';

import { WidgetContainer } from 'components';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { CachedQuestionInstance, QuestionKey } from '../../types';
import * as ControlsBar from '../ControlsBar';
import * as Content from './Content';
import { QuestionContext } from './Context';
import * as Header from './Header';
import './style.scss';

export { QuestionContext, QuestionContextProvider } from './Context';

const b = block('quiz-constructor-widget-for-question');

type Props = {
  Title?: React.FC;
  onQuestionTypeChange?(
    instance: CachedQuestionInstance,
    questionKey: QuestionKey,
    setQuestionType: (questionKey: QuestionKey) => void,
  ): void;
};

function ConstructorForQuestion({ Title, onQuestionTypeChange }: Props) {
  const { cachedInstance } = useRequiredContext(QuestionContext);

  const handleQuestionTypeChange = useCallback(
    (
      questionKey: QuestionKey,
      setQuestionType: (questionKey: QuestionKey) => void,
    ) => {
      onQuestionTypeChange?.(cachedInstance, questionKey, setQuestionType);
    },
    [cachedInstance, onQuestionTypeChange],
  );

  return (
    <div className={b()}>
      <Header.DependenciesContext.Provider
        onQuestionTypeChange={handleQuestionTypeChange}
      >
        <WidgetContainer.Component
          ControlsBar={ControlsBar.Component}
          Title={Title}
          Content={Content.Component}
          Header={Header.Component}
        />
      </Header.DependenciesContext.Provider>
    </div>
  );
}

export const Component = React.memo(ConstructorForQuestion);
