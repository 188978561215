import { useRef } from 'react';

import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { nonEmptyString, nonFalse } from 'utils/validators';

import { isRequired, questionIsRequired } from '../i18nSharedReferences';

export type StateItem = Record<
  string,
  {
    valueState: FormElementState<boolean>;
    textState: FormElementState<string>;
  }
>;

export const makeState = (
  question: M.MultiChoiceQuestion,
  initialValue: string[] = [],
  initialText: Record<string, string> = {},
) => {
  return makeFormElementState<StateItem>(
    question.variants.reduce(
      (acc, x) => ({
        ...acc,
        [x.uuid]: {
          valueState: makeFormElementState(
            initialValue.includes(x.uuid),
            !question.optional ? [nonFalse(questionIsRequired)] : [],
          ),
          textState: makeFormElementState(
            initialText[x.uuid] || '',
            !question.optional && x.custom ? [nonEmptyString(isRequired)] : [],
          ),
        },
      }),
      {},
    ),
  );
};

export const useState = (
  question: M.MultiChoiceQuestion,
  initialValue?: string[],
  initialText?: Record<string, string>,
) => {
  return useRef(makeState(question, initialValue, initialText)).current;
};
