import * as M from 'types/serverModels';

export const getVideoSrc = (video: M.VideoFromHosting) => {
  switch (video.host) {
    case 'youtube':
      return `https://www.youtube.com/embed/${video.code}?wmode=opaque`;
    case 'vimeo':
      return `https://player.vimeo.com/video/${video.code}?byline=0&portrait=0&badge=0&color=c2c2c2`;
  }
};

export const getVideoThumbSrc = (video: M.VideoFromHosting) => {
  switch (video.host) {
    case 'youtube':
      return `https://img.youtube.com/vi/${video.code}/default.jpg`;
    case 'vimeo':
      return `https://vumbnail.com/${video.code}_small.jpg`;
  }
};
