import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { I18n } from 'services';
import { QUESTION_VISIBILITY } from 'shared/constants';
import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonEmptyString } from 'utils/validators';

import { questionIsRequired } from '../../i18nSharedReferences';
import { TextQuestion } from '../../subfeatures';
import { Kind, Type } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';

type Props = {
  data:
    | M.StringQuestion
    | M.TextQuestion
    | M.TextQuizQuestion
    | M.StringQuizQuestion;
  num: number;
  kind: Kind;
  type: Type;
  initialValue?: string;
  isMultiline?: boolean;
  result?: M.QuizAnswerResult;
  onChange?(): void;
};

export const stateUnit = makePrimaryUnit<
  Record<string, FormElementState<string>>
>({});

function Question({
  data,
  num,
  initialValue = '',
  isMultiline = false,
  kind,
  type,
  result,
  onChange,
}: Props) {
  const isOptional = 'optional' in data && data.optional;
  const visibility = 'visibility' in data && data.visibility;

  const hasChanged = useRef(false);

  const validators = useMemo(
    () => (!isOptional ? [nonEmptyString(questionIsRequired)] : []),
    [isOptional],
  );

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    defaultValue: '',
    initialValue,
    validators,
  });

  const value = formElementState.units.value.useState();

  const t = I18n.useGetMultilingProjectTranslation();

  const title = t(data.title);
  const description = t(data.description);
  const answer = t(data.answer);

  const handleBlur = useCallback(() => {
    const visited = formElementState.units.visited.getState();

    if (hasChanged.current && visited) {
      onChange?.();
    }
    hasChanged.current = false;
  }, [formElementState.units.visited, onChange]);

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  useEffect(() => {
    return formElementState.units.value.subscribe({
      name: 'change-value',
      callback: (nextState, prevState) => {
        if (nextState !== prevState) {
          hasChanged.current = true;
        }
      },
    });
  }, [formElementState, onChange]);

  return (
    <QuestionLayout.Component
      title={title}
      description={description}
      num={num}
      image={data.image?.large}
      type={type}
      variant={
        (type === 'quiz' && kind === 'form') || value.length === 0
          ? 'outlined'
          : 'contained'
      }
      isOptional={isOptional}
      isEmpty={kind === 'view' && value.length === 0}
      isAutocomplete={visibility === QUESTION_VISIBILITY.disabled}
      result={result}
      answer={kind === 'view' ? answer : undefined}
    >
      <TextQuestion.Component
        formElementState={formElementState}
        isMultiline={isMultiline}
        kind={kind}
        onBlur={handleBlur}
      />
    </QuestionLayout.Component>
  );
}
export const Component = React.memo(Question);
