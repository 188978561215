import React, { useRef } from 'react';

import { block } from 'utils/classname';

import * as ResizeObserver from './ResizeObserver';
import './style.scss';

const b = block('modal-footer-controls-layout');

type Props = React.PropsWithChildren<{
  gap: 'l' | 'm' | null;
  position?: 'static' | 'absolute';
  justifyContent?: 'stretch' | 'start' | 'center';
  controlMinWidth?: number;
}>;

function Controls({
  gap,
  position = 'static',
  justifyContent = 'center',
  controlMinWidth = 125,
  children,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        className={b({
          gap: gap ?? undefined,
          position,
          'justify-content': justifyContent,
        })}
        style={{
          ['--control-min-width' as any]: `${controlMinWidth}px`,
        }}
        ref={ref}
      >
        {children}
      </div>
      {position === 'absolute' && (
        <div className={b('placeholder')} ref={placeholderRef} />
      )}
      <ResizeObserver.Component rootRef={ref} placeholderRef={placeholderRef} />
    </>
  );
}

export const Component = Controls;
