import { makeSharedEntryReference } from 'services/I18n';

import { i18nStageStatusSharedReferences } from '../../../../i18nStageStatusSharedReferences';
import { i18nStatusSharedReferences } from '../../../../i18nStatusSharedReferences';
import { i18nNavigationLinkSharedReferences } from '../../Navigation';
import { i18nPassportFieldsSharedReferences } from '../Passport';
import { i18nStagesStageSectionTitleSharedReferences } from '../Stages';

export const stageStatusReferences = i18nStageStatusSharedReferences;
export const statusReferences = i18nStatusSharedReferences;
export const navigationReferences = i18nNavigationLinkSharedReferences;
export const passportFieldsReferences = i18nPassportFieldsSharedReferences;
export const stageSectionTitleReferences =
  i18nStagesStageSectionTitleSharedReferences;

export const projectBookChangeEventLabelReferences = {
  passport_change: makeSharedEntryReference(
    data => data.projectBook.change.event.passport_change.label,
  ),
  fields_change: makeSharedEntryReference(
    data => data.projectBook.change.event.fields_change.label,
  ),
  fields_units_change: makeSharedEntryReference(
    data => data.projectBook.change.event.fields_units_change.label,
  ),
  status_change: makeSharedEntryReference(
    data => data.projectBook.change.event.status_change.label,
  ),
  stages_task_states_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_task_states_change.label,
  ),
  date_change: makeSharedEntryReference(
    data => data.projectBook.change.event.date_change.label,
  ),
  assessment_change: makeSharedEntryReference(
    data => data.projectBook.change.event.assessment_change.label,
  ),
  stages_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_change.label,
  ),
  stages_status_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_status_change.label,
  ),
  stages_date_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_date_change.label,
  ),
  stages_assessment_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_assessment_change.label,
  ),
  stages_board_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_board_change.label,
  ),
  stages_units_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_units_change.label,
  ),
  stages_materials_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_materials_change.label,
  ),
  stages_boards_comments_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_boards_comments_change.label,
  ),
  stages_materials_comments_change: makeSharedEntryReference(
    data =>
      data.projectBook.change.event.stages_materials_comments_change.label,
  ),
  reflexion_comments_change: makeSharedEntryReference(
    data => data.projectBook.change.event.reflexion_comments_change.label,
  ),
  stages_tasks_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_tasks_change.label,
  ),
  members_supervisors_change: makeSharedEntryReference(
    data => data.projectBook.change.event.members_supervisors_change.label,
  ),
  members_participants_change: makeSharedEntryReference(
    data => data.projectBook.change.event.members_participants_change.label,
  ),
  members_watchers_change: makeSharedEntryReference(
    data => data.projectBook.change.event.members_watchers_change.label,
  ),
};
