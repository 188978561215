import * as R from 'ramda';
import React, { useCallback, useMemo } from 'react';

import { VerticallyMovableElement } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { ConstructorFormSection } from 'features/project/Constructor/subfeatures';
import {
  makePrimaryUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  PrimaryStateUnit,
} from 'utils/State';
import { useRequiredContext } from 'utils/react/RequiredContext';

import {
  GroupedStoreCachedInstances,
  GroupedWidgetPath,
  StoreCachedInstances,
  UngroupedStoreCachedInstances,
  UngroupedWidgetPath,
  ConstructorWidgetCachedInstance,
  WidgetInstanceView,
  WidgetPath,
  CachedQuestionInstance,
  QuestionKey,
} from '../types';
import { WidgetContext } from './Context';
import * as ForQuestion from './ForQuestion';
import * as ForQuestionSelection from './ForQuestionSelection';
import * as instanceMoving from './instanceMoving';

type Props = {
  widgetInstanceView: VerticallyMovableElement.Element<WidgetInstanceView>;
  prevInstance?: ConstructorWidgetCachedInstance;
  cachedStoreInstancesUnit: PrimaryStateUnit<StoreCachedInstances>;
  onQuestionTypeChange?(
    instance: CachedQuestionInstance,
    questionKey: QuestionKey,
    setQuestionType: (questionKey: QuestionKey) => void,
  ): void;
  isDeletionPermitted?(questionID: string): boolean;
};

function useWidgetNumber(
  path: WidgetPath,
  cachedStoreInstancesUnit: PrimaryStateUnit<StoreCachedInstances>,
) {
  const instances = cachedStoreInstancesUnit.useState();

  if (instances.kind === 'ungrouped') {
    return (path as UngroupedWidgetPath).index + 1;
  }

  const groupedPath = path as GroupedWidgetPath;

  const numberOfWidgetsBefore = instances.groups
    .slice(0, groupedPath.groupIndex)
    .map(x => x.instances.length)
    .reduce((acc, x) => acc + x, 0);

  return numberOfWidgetsBefore + groupedPath.widgetIndex + 1;
}

const makeHasErrorUnit = (instance: CachedQuestionInstance) =>
  makeMappingUnitFromUnit(
    makeDerivedUnit(instance.activeQuestionKeyUnit).getUnit(
      key => instance.instancesCache[key].hasErrorUnit,
    ),
  );

function ConstructorWidget({
  widgetInstanceView,
  prevInstance,
  cachedStoreInstancesUnit,
  isDeletionPermitted = () => true,
  onQuestionTypeChange,
}: Props) {
  const { path } = widgetInstanceView.value;
  const widgetNumber = useWidgetNumber(path, cachedStoreInstancesUnit);

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleWidgetDelete = useCallback(() => {
    if (isDeletionPermitted(widgetInstanceView.value.instance.id)) {
      cachedStoreInstancesUnit.setState(prev => {
        const lens =
          path.kind === 'ungrouped'
            ? R.lensPath<UngroupedStoreCachedInstances, 'instances'>([
                'instances',
              ])
            : R.lensPath<
                GroupedStoreCachedInstances,
                'groups',
                number,
                'instances'
              >(['groups', path.groupIndex, 'instances']);

        return R.over(
          lens as R.Lens<
            StoreCachedInstances,
            ConstructorWidgetCachedInstance[]
          >,
          instances =>
            R.without([widgetInstanceView.value.instance], instances),
          prev,
        );
      });
      if (widgetInstanceView.value.instance.kind !== 'select-instance-kind') {
        saveProject();
      }
    }
  }, [
    cachedStoreInstancesUnit,
    isDeletionPermitted,
    path,
    saveProject,
    widgetInstanceView.value.instance,
  ]);

  const handleMoveWidgetPrev = useCallback(() => {
    cachedStoreInstancesUnit.setState(prev => {
      if (path.kind === 'ungrouped' && prev.kind === 'ungrouped') {
        if (path.index === 0) return prev;

        const next: UngroupedStoreCachedInstances = {
          ...prev,
          instances: R.move(path.index, path.index - 1, prev.instances),
        };

        saveProject();

        return next;
      } else if (path.kind === 'grouped' && prev.kind === 'grouped') {
        const next = instanceMoving.moveGroupedPrev(
          widgetInstanceView.value.instance,
          path,
          prev,
        );

        saveProject();

        return next;
      } else {
        console.error('unexpected condition', prev.kind, path.kind);
        return prev;
      }
    });
  }, [
    cachedStoreInstancesUnit,
    path,
    saveProject,
    widgetInstanceView.value.instance,
  ]);

  const handleMoveWidgetNext = useCallback(() => {
    cachedStoreInstancesUnit.setState(prev => {
      if (path.kind === 'ungrouped' && prev.kind === 'ungrouped') {
        if (path.index === prev.instances.length - 1) return prev;

        const next: UngroupedStoreCachedInstances = {
          ...prev,
          instances: R.move(path.index, path.index + 1, prev.instances),
        };

        saveProject();

        return next;
      } else if (path.kind === 'grouped' && prev.kind === 'grouped') {
        const next = instanceMoving.moveGroupedNext(
          widgetInstanceView.value.instance,
          path,
          prev,
        );

        saveProject();

        return next;
      } else {
        console.error('unexpected condition', prev.kind, path.kind);
        return prev;
      }
    });
  }, [
    cachedStoreInstancesUnit,
    path,
    saveProject,
    widgetInstanceView.value.instance,
  ]);

  const hasErrorUnit = useMemo(() => {
    // TODO make it better
    return widgetInstanceView.value.instance.kind === 'select-instance-kind'
      ? makePrimaryUnit(false)
      : makeHasErrorUnit(widgetInstanceView.value.instance);
  }, [widgetInstanceView.value.instance]);

  const hasError = hasErrorUnit.useState();

  const prevWidgetHasErrorUnit = useMemo(() => {
    if (prevInstance?.kind === 'question-instance') {
      return makeHasErrorUnit(prevInstance);
    }

    return makePrimaryUnit(false);
  }, [prevInstance]);
  const prevWidgetHasError = prevWidgetHasErrorUnit.useState();

  return (
    <VerticallyMovableElement.Component element={widgetInstanceView}>
      <ConstructorFormSection.Component
        hasError={hasError}
        prevHasError={prevWidgetHasError}
      >
        <WidgetContext.Provider
          onDelete={handleWidgetDelete}
          onMovePrev={handleMoveWidgetPrev}
          onMoveNext={handleMoveWidgetNext}
        >
          {(() => {
            if (
              widgetInstanceView.value.instance.kind === 'select-instance-kind'
            ) {
              return (
                <ForQuestionSelection.Component
                  path={path}
                  widgetNumber={widgetNumber}
                  cachedStoreInstancesUnit={cachedStoreInstancesUnit}
                />
              );
            }

            return (
              <ForQuestion.QuestionWidgetContextProvider
                cachedInstance={widgetInstanceView.value.instance}
              >
                <ForQuestion.Component
                  path={path}
                  widgetNumber={widgetNumber}
                  onQuestionTypeChange={onQuestionTypeChange}
                />
              </ForQuestion.QuestionWidgetContextProvider>
            );
          })()}
        </WidgetContext.Provider>
      </ConstructorFormSection.Component>
    </VerticallyMovableElement.Component>
  );
}

export const Component = React.memo(
  ConstructorWidget,
) as typeof ConstructorWidget;
