import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('widget-container');

type Props = {
  Title?: React.FC;
  Content: React.FC;
  Header?: React.FC;
  ControlsBar: React.FC;
};

function WidgetContainer({ Title, Content, Header, ControlsBar }: Props) {
  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('header-left-part')}>
          {Title && <Title />}
          {Header && <Header />}
        </div>
        <div className={b('header-right-part')}>
          <div className={b('controls-bar')}>
            <ControlsBar />
          </div>
        </div>
      </div>
      <div className={b('content')}>
        <Content />
      </div>
    </div>
  );
}

export const Component = React.memo(WidgetContainer);
