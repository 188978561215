import Highcharts from 'highcharts';
import venn from 'highcharts/modules/venn';
import wordCloud from 'highcharts/modules/wordcloud';
import { useEffect, useRef, useState } from 'react';
import React from 'react';

import { I18n } from 'services';
import { ReactComponent as NextButtonIcon } from 'shared/images/chart/next-button.svg';
import { ReactComponent as PrevButtonIcon } from 'shared/images/chart/previous-button.svg';
import * as TS from 'types';
import { block } from 'utils/classname';

import { customPlacement } from './customPlacement';
import { getHighchartOptions } from './getHighchartsOptions';
import './style.scss';

const b = block('chart');

type Props = {
  data: TS.ChartData;
  className?: string;
  step?: number;
};

function Chart({ data, className, step }: Props) {
  const getFormattedDate = I18n.useGetFormattedDate();

  const ref = useRef<HTMLDivElement>(null);
  const chartRef = useRef<Highcharts.Chart | null>(null);

  useEffect(() => {
    switch (data.type) {
      case 'venn': {
        venn(Highcharts);
        break;
      }
      case 'wordcloud': {
        wordCloud(Highcharts);
        (
          Highcharts as any
        ).seriesTypes.wordcloud.prototype.placementStrategy.custom =
          customPlacement;
        break;
      }
    }
  }, [data.type]);

  useEffect(() => {
    if (ref.current) {
      const options = getHighchartOptions({
        data,
        getFormattedDate,
      });

      chartRef.current = Highcharts.chart(ref.current, options);
    }
  }, [data, getFormattedDate]);

  const [isShowPrev, setShowPrev] = useState(false);
  const [isShowNext, setShowNext] = useState(false);
  const [xAxisMax, setXAxisMax] = useState<number | null>(null);
  const [currPage, setCurrPage] = useState(1);

  const onNext = () => {
    if (chartRef.current && step) {
      const stepWithDelta = step + 1;
      const chart = chartRef.current;
      const currentMin = chart.xAxis[0].getExtremes().min;
      const currentMax = chart.xAxis[0].getExtremes().max;

      chart.xAxis[0].setExtremes(
        currentMin + stepWithDelta,
        currentMax + stepWithDelta,
      );
      setShowPrev(currentMin + stepWithDelta !== 0);
      setShowNext(
        currentMax + stepWithDelta < chart.xAxis[0].categories.length - 1,
      );
      setXAxisMax(currentMax + stepWithDelta);
    }
  };

  const onPrev = () => {
    if (chartRef.current && step) {
      const stepWithDelta = step + 1;
      const chart = chartRef.current;
      const currentMin = chart.xAxis[0].getExtremes().min;
      const currentMax = chart.xAxis[0].getExtremes().max;

      chart.xAxis[0].setExtremes(
        currentMin - stepWithDelta,
        currentMax - stepWithDelta,
      );
      setShowPrev(currentMin - stepWithDelta !== 0);
      setShowNext(
        currentMax - stepWithDelta < chart.xAxis[0].categories.length - 1,
      );
      setXAxisMax(currentMax - stepWithDelta);
    }
  };

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      const currentMin = chart.xAxis[0].getExtremes().min;
      const currentMax = chart.xAxis[0].getExtremes().max;
      setShowPrev(currentMin !== 0);
      setShowNext(currentMax < chart.xAxis[0].categories.length - 1);
      setXAxisMax(currentMax);
    }
  }, []);

  useEffect(() => {
    if (xAxisMax && step) {
      const currPage = Math.round((xAxisMax + 1) / (step + 1));
      setCurrPage(currPage);
    }
  }, [step, xAxisMax]);

  useEffect(() => {
    const handleResize = () => {
      chartRef.current?.reflow();
    };

    const resizeObserver = new globalThis.ResizeObserver(handleResize);

    if (ref.current !== null) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <div className={b({}, [className])} ref={ref} />
      {step && (
        <div className={b('controls')}>
          <div className={b('buttons')}>
            <div
              className={b('prev')}
              onClick={isShowPrev ? onPrev : undefined}
            >
              <PrevButtonIcon
                className={b('prev-icon', { disabled: !isShowPrev })}
              />
            </div>
            <div
              className={b('next')}
              onClick={isShowNext ? onNext : undefined}
            >
              <NextButtonIcon
                className={b('next-icon', { disabled: !isShowNext })}
              />
            </div>
          </div>
          {chartRef.current && (
            <div className={b('pages')}>
              {currPage}/
              {Math.ceil(
                chartRef.current.xAxis[0].categories.length / (step + 1),
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export const Component = React.memo(Chart);
