import React, { useCallback, useMemo } from 'react';

import { WidgetContainer, WidgetNumberMark } from 'components';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { CachedQuestionInstance, QuestionKey, WidgetPath } from '../../types';
import * as ControlsBar from '../ControlsBar';
import * as Content from './Content';
import { QuestionWidgetContext } from './Context';
import * as Header from './Header';
import './style.scss';

export {
  QuestionWidgetContext,
  QuestionWidgetContextProvider,
} from './Context';

const b = block('constructor-widget-for-question');

type Props = {
  path: WidgetPath;
  widgetNumber: number;
  onQuestionTypeChange?(
    instance: CachedQuestionInstance,
    questionKey: QuestionKey,
    setQuestionType: (questionKey: QuestionKey) => void,
  ): void;
};

function ConstructorWidgetForQuestion(props: Props) {
  const { widgetNumber, onQuestionTypeChange } = props;

  const Title = useMemo(
    () =>
      WidgetNumberMark.makeComponent({
        value: widgetNumber,
      }),
    [widgetNumber],
  );
  const { cachedInstance } = useRequiredContext(QuestionWidgetContext);

  const handleQuestionTypeChange = useCallback(
    (
      questionKey: QuestionKey,
      setQuestionType: (questionKey: QuestionKey) => void,
    ) => {
      onQuestionTypeChange?.(cachedInstance, questionKey, setQuestionType);
    },
    [cachedInstance, onQuestionTypeChange],
  );

  return (
    <div className={b()}>
      <Header.DependenciesContext.Provider
        onQuestionTypeChange={handleQuestionTypeChange}
      >
        <WidgetContainer.Component
          ControlsBar={ControlsBar.Component}
          Title={Title}
          Content={Content.Component}
          Header={Header.Component}
        />
      </Header.DependenciesContext.Provider>
    </div>
  );
}

export const Component = React.memo(
  ConstructorWidgetForQuestion,
) as typeof ConstructorWidgetForQuestion;
