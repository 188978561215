import debounce from 'debounce';
import React, { useCallback, useEffect, useMemo } from 'react';

import { API, I18n } from 'services';
import { userStateUnit } from 'shared/stateUnits';
import * as M from 'types/serverModels';
import { QuizWrite } from 'utils/business';
import { createRequiredContext } from 'utils/react/RequiredContext';

import { makeServerQuestion } from './Questions/makeServerQuestion';
import {
  writeCallStateUnit,
  descriptionState,
  questionInstancesUnit,
  quizLanguageUnit,
  quizUnit,
  titleState,
} from './units';

type QuizWriteContextData = {
  saveQuiz(): void;
};

export const QuizWriteContext = createRequiredContext<QuizWriteContextData>();

export function QuizWriteContextProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const post = API.services.quiz.post.useCall(writeCallStateUnit);
  const put = API.services.quiz.put.useCall(writeCallStateUnit);

  const saveQuiz = useCallback(() => {
    if (writeCallStateUnit.getState().kind === 'pending') {
      setTimeout(() => saveQuiz(), 100);
      return;
    }
    const instances = questionInstancesUnit.getState();
    const lang = quizLanguageUnit.getState();
    const userState = userStateUnit.getState();

    if (userState.kind !== 'loaded') return;

    const quiz = quizUnit.getState();

    const requestData = {
      title: I18n.makeMultilangFormState(
        titleState,
        quiz?.title,
      ).getMergedMultilingString(lang),
      questions: instances
        .map(makeServerQuestion)
        .filter((x): x is M.QuizQuestion => x !== null),
      description: I18n.makeMultilangFormState(
        descriptionState,
        quiz?.description,
      ).getMergedMultilingString(lang),
    };

    if (QuizWrite.equals(quiz, requestData)) return;

    if (quiz?.uuid) {
      put({ uuid: quiz.uuid, quiz: requestData });
    } else {
      post({ quiz: requestData });
    }
  }, [post, put]);

  const saveWithDebounce = useMemo(() => debounce(saveQuiz, 1000), [saveQuiz]);

  useEffect(() => {
    return () => {
      saveWithDebounce.clear();
    };
  }, [saveWithDebounce]);

  return (
    <QuizWriteContext.Provider saveQuiz={saveQuiz}>
      {children}
    </QuizWriteContext.Provider>
  );
}
