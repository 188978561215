import React, { useCallback, useMemo } from 'react';

import { Button, VerticallyMovableElement } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { ConstructorFormSection } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import {
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
} from 'utils/State';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { Step } from '../../types';
import { makeStepVisitedUnit } from '../../utils';
import { Question } from '../shared';
import { PermissionContext } from '../shared/PermissionContext';
import * as DescriptionEditor from './DescriptionEditor';
import { config } from './config';
import { loadAndFillConclusions } from './loadAndFillConclusions';
import './style.scss';
import { descriptionState } from './units/description';
import { errorsUnit } from './units/errors';
import { hasSomeInputUnit } from './units/hasSomeInput';
import {
  cachedStoreInstancesUnit,
  flatCachedInstancesUnit,
  questionInstancesUnit,
} from './units/instances';
import { quizUnit } from './units/quizUnit';

const b = block('conclusions');

type Props = {};

const useVerticallyMovableELements =
  VerticallyMovableElement.makeUseVerticallyMovableELements<Question.InstanceView>(
    x => (x.kind === 'group-header' ? x.id : x.instance.id),
  );

const isAddQuestionButtonDisabledUnit = makeDerivedUnit(
  makeMappingUnitFromUnit(
    makeDerivedUnit(flatCachedInstancesUnit).getUnit(questionInstances =>
      questionInstances.map(
        instance =>
          instance.kind === 'select-instance-kind' ||
          makeDerivedUnit(instance.mode).getUnit(x => x === 'edit'),
      ),
    ),
  ),
).getUnit(x => x.some(x => x));

function Conclusions({}: Props) {
  const text = I18n.useText(i18nData);
  const instances = cachedStoreInstancesUnit.useState();

  const instanceViews = useMemo(
    () => Question.getInstanceViews(instances),
    [instances],
  );

  const isAddQuestionButtonDisabled =
    isAddQuestionButtonDisabledUnit.useState();

  const { getQuestionTypeChangePermission } =
    useRequiredContext(PermissionContext);

  const handleQuestionTypeChange = useCallback(
    (
      cachedInstance: Question.CachedQuestionInstance,
      questionKey: Question.QuestionKey,
    ) => {
      const permission = getQuestionTypeChangePermission(
        cachedInstance.id,
        Question.getQuestionKindsSet(questionKey),
      );

      if (permission.kind === 'granted') {
        Question.setInstanceQuestionKind(
          cachedInstance,
          permission.questionKind,
        );
      }
    },
    [getQuestionTypeChangePermission],
  );

  const handleAddQuestionButtonClick = useCallback(() => {
    cachedStoreInstancesUnit.setState(prev => {
      if (prev.kind === 'ungrouped') {
        return {
          ...prev,
          instances: [...prev.instances, Question.makeSelectionInstance()],
        };
      } else {
        throw Error('unexpected grouped instances at conclusions step');
      }
    });
  }, []);

  const movableInstanceViews = useVerticallyMovableELements(instanceViews);

  return (
    <div className={b()}>
      <DescriptionEditor.Component />
      <Question.QuestionsConfigContext.Provider {...config}>
        {movableInstanceViews.map((movableInstanceView, index) => {
          if (movableInstanceView.value.kind === 'widget') {
            const prevInstance =
              index > 0 ? movableInstanceViews[index - 1].value : null;
            return (
              <Question.ConstructorWidget.Component
                key={movableInstanceView.value.instance.id}
                cachedStoreInstancesUnit={cachedStoreInstancesUnit}
                onQuestionTypeChange={handleQuestionTypeChange}
                widgetInstanceView={
                  movableInstanceView as VerticallyMovableElement.Element<Question.WidgetInstanceView>
                }
                prevInstance={
                  prevInstance?.kind === 'widget'
                    ? prevInstance.instance
                    : undefined
                }
              />
            );
          }

          throw Error('unexpected group header kind for Conclusions step');
        })}
      </Question.QuestionsConfigContext.Provider>
      <ConstructorFormSection.Component>
        <div className={b('add-question-and-stage-buttons')}>
          <Button.Component
            type="button"
            className={b('add-question-button')}
            disabled={isAddQuestionButtonDisabled}
            onClick={handleAddQuestionButtonClick}
          >
            {text.steps.shared.questions.addQuestionButtonLabel}
          </Button.Component>
        </div>
      </ConstructorFormSection.Component>
    </div>
  );
}

const Component = React.memo(Conclusions) as typeof Conclusions;

const visitedUnit = makeStepVisitedUnit();

export const step: Step = {
  key: 'conclusions',
  Form: Component,
  errorsUnit,
  visitedUnit,
  progressInvariantUnitsUnit: makePrimaryUnit([]),
  hasSomeInputUnit,
  // NOTE we save conclusions form as quiz at higher level using separate API call
  // here we just get the uuid of saved quiz
  getProjectData: ({ serverProject }) => {
    const isEmpty =
      descriptionState.units.value.getState() === '' &&
      questionInstancesUnit
        .getState()
        .filter(x => x.mode.getState() === 'preview').length === 0;

    if (isEmpty) {
      return {
        conclusions: null,
      };
    }
    return {
      conclusions: serverProject?.conclusions || quizUnit.getState()?.uuid,
    };
  },
  fillFromExistingProject: ({ project }) => {
    loadAndFillConclusions(project);
  },
  resetState: () => {
    quizUnit.resetState();
    cachedStoreInstancesUnit.resetState();
    visitedUnit.resetState();
  },
  resetStateAfterDelay: () => {
    descriptionState.formNode.reset();
  },
};
