import React from 'react';

import { MapWidgetLayers } from 'features/project/Constructor/subfeatures';
import { usePrimaryUnit } from 'utils/State';

import { LayersProps } from '../../../types';
import { MapInstance } from '../types';

function Layers({ instance }: LayersProps<MapInstance>) {
  const activeLayerIndexUnit = usePrimaryUnit<number>(0);

  return (
    <MapWidgetLayers.Component
      layersUnit={instance.layers}
      activeLayerIndexUnit={activeLayerIndexUnit}
    />
  );
}

export const Component = React.memo(Layers);
