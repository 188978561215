import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';

import { I18n } from 'services';
import { QUESTION_VISIBILITY } from 'shared/constants';
import { userStateUnit } from 'shared/stateUnits';
import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonNull } from 'utils/validators';

import { questionIsRequired } from '../../i18nSharedReferences';
import { MapQuestion } from '../../subfeatures';
import { Kind, Type } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';

type Props = {
  data: M.MapQuestion | M.MapQuizQuestion;
  num: number;
  kind: Kind;
  type: Type;
  initialValue: M.Location | null;
  isDisable: boolean;
  result?: M.QuizAnswerResult;
  onChange?(): void;
};

export const stateUnit = makePrimaryUnit<
  Record<string, FormElementState<M.Location | null>>
>({});

function Question({
  data,
  num,
  initialValue = null,
  kind,
  type,
  isDisable,
  result,
  onChange,
}: Props) {
  const userState = userStateUnit.useState();

  const userLocation = useMemo(
    (): M.Location =>
      userState.kind === 'loaded'
        ? [userState.user.location.longitude, userState.user.location.latitude]
        : [0, 0],
    [userState],
  );

  const presetLocation = useMemo(
    () => (userLocation.every(x => x === 0) ? null : userLocation),
    [userLocation],
  );

  const isOptional = 'optional' in data && data.optional;
  const visibility = 'visibility' in data && data.visibility;

  const validators = useMemo(
    () => (!isOptional ? [nonNull(questionIsRequired)] : []),
    [isOptional],
  );

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    defaultValue: null,
    initialValue: !isOptional ? initialValue || presetLocation : initialValue,
    validators,
  });

  const value = formElementState.units.value.useState();

  const t = I18n.useGetMultilingProjectTranslation();

  const title = t(data.title);
  const description = t(data.description);
  const answer = t(data.answer);

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  useEffect(() => {
    return formElementState.units.value.subscribe({
      name: 'change-value',
      callback: (nextState, prevState) => {
        setTimeout(() => {
          const visited = formElementState.units.visited.getState();

          if (!R.equals(nextState, prevState) && visited) {
            onChange?.();
          }
        });
      },
    });
  }, [formElementState, onChange]);

  return (
    <QuestionLayout.Component
      title={title}
      description={description}
      image={data.image?.large}
      type={type}
      num={num}
      variant={
        (type === 'quiz' && kind === 'form') || value === null
          ? 'outlined'
          : 'contained'
      }
      isOptional={isOptional}
      isEmpty={kind === 'view' && value === null}
      isAutocomplete={visibility === QUESTION_VISIBILITY.disabled}
      result={result}
      answer={kind === 'view' ? answer : undefined}
    >
      <MapQuestion.Component
        formElementState={formElementState}
        optional={isOptional}
        kind={
          visibility === QUESTION_VISIBILITY.disabled || isDisable
            ? 'view'
            : kind
        }
      />
    </QuestionLayout.Component>
  );
}
export const Component = React.memo(Question);
