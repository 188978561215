import type * as ProjectViewModal from 'features/project/view/Modal';

import { projectXShortsUnit } from '../units';

export const handleQuestionnaireComplete: ProjectViewModal.Props['onQuestionnaireComplete'] =
  ({ project }) => {
    const projectXShorts = projectXShortsUnit.getState();
    const projectUnit = projectXShorts?.[project.uuid];

    if (projectUnit === undefined) {
      console.error('undefined project');

      return;
    }

    projectUnit.setState(prev => ({
      ...prev,
      results_counter: (prev.results_counter || 0) + 1,
    }));
  };
